import React, { useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { Card, CardTitle, CardText, Media, MediaOverlay } from 'react-md';
import { useStore } from '../../../store.js';
import './rettsacademy.scss';
import { middlewares } from '../../../middlewares';

const simLinkLogo = require('@internal/assets/sim-link-bg.jpg');
const eLearningLinkLogo = require('@internal/assets/elarning-link-bg.jpg');

const RettsAcademy = props => {
    const {
    dispatch } = useStore();
    const { t } = props;

    const login = useCallback(data => dispatch({ type: 'login', payload: data }), [dispatch]);
    const logout = useCallback(data => dispatch({ type: 'logout', payload: data }), [dispatch]);
    const setLogo = useCallback(data => dispatch({ type: 'setLogo', payload: data }), [dispatch]);

    useEffect(() => {
        setLogo('RETTSAcademy');
        const getUserData = async () => {
           await middlewares.getUserData()
            .then(data => {
                data.logo = 'RETTSAcademy';
                data.isUserLoggedIn ? login(data) : logout(data);
            });
        };

        localStorage.getItem('isRefreshed') === 'true' && getUserData();
    }, [login, logout, setLogo]);

    const setPath = useCallback(data => dispatch({ type: 'setPath', payload: data }), [dispatch]);

    const goToRettsElearning = () => {
        setPath('/elearning/student/dashboard');
    };

    const goToRettsSim = () => {
        setPath('/rettssim/student/dashboard');
    };

    return (
        <div className="rettsacademy">
            <Card className="md-block-centered rettsacademycard rettselearning" onClick={goToRettsElearning}>
                <CardText>
                    <Media>
                        <img src={eLearningLinkLogo} alt="RettseLearning" />
                        <MediaOverlay>
                            <CardTitle title="RETTS eLearning" subtitle="eLearning" />
                        </MediaOverlay>
                    </Media>
                </CardText>
            </Card>
            <Card
                style={{ display: 'none' }}
                className="md-block-centered rettsacademycard rettssim"
                onClick={goToRettsSim}
                >
                <div className="ribbon ribbon-top-left">
                    <span>{t('COMING_SOON')}</span></div>
                <CardText>
                    <Media>
                        <img src={simLinkLogo} alt="RettsSim" />
                        <MediaOverlay>
                            <CardTitle title="RETTS Sim" subtitle="Patientfall" />
                        </MediaOverlay>
                    </Media>
                </CardText>
            </Card>
            <br/>
        </div>
    );
};

export default withTranslation()(RettsAcademy);
