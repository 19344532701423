import axios from 'axios';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';

const validateQuestions = question => {
    let validation = true;
    let msg = '';
    _map(question.results, result => {
        if ((result.value === '') && (result.key !== 'ESS')) {
            validation = false;
            msg = `${result.label} is empty`;
        }
        if (!question.selectedEssSymptomsList.length) {
            validation = false;
            msg = 'ESS symptoms is empty';
        }
        if (_isEmpty(question.selectedEssData)) {
            validation = false;
            msg = 'ESS data is empty';
        }
        if (_isEmpty(question.selectedEssData)) {
            validation = false;
            msg = 'ESS data is empty';
        }
    });

    return ({ validation, msg });
};

const addNewQuestion = async data => {
    const validation = await validateQuestions(data.newQuestion);
    if (validation.validation) {
        return axios.post(`${process.env.REACT_APP_API_BASEURL}/questions`,
        { data },
        { headers: { accessToken: localStorage.getItem('accessToken') } }
    )
    .then(response => {
        return { ...response.data, validation: true };
    });
    }

return validation;
};

const getAllQuestions = () => {
    return axios.get(`${process.env.REACT_APP_API_BASEURL}/questions`,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const getQuestionDetailById = data => {
    return axios.post(`${process.env.REACT_APP_API_BASEURL}/questions/getQuestionsById`, { data },
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const getQuestionsByFilter = data => {
    return axios.post(`${process.env.REACT_APP_API_BASEURL}/questions/getQuestionsByFilter`,
        { data },
        { headers: { accessToken: localStorage.getItem('accessToken') } }
    )
    .then(response => {
        return response.data;
    });
};

const deleteQuestion = data => {
    return axios.put(`${process.env.REACT_APP_API_BASEURL}/questions`, { data },
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const updateQuestion = async data => {
    const validation = await validateQuestions(data);
    if (validation.validation) {
        return axios.put(`${process.env.REACT_APP_API_BASEURL}/questions`,
        { data },
        { headers: { accessToken: localStorage.getItem('accessToken') } }
    )
    .then(response => {
        return { ...response.data, validation: true };
    });
    }

return validation;
};

const getAllPackages = () => {
    const token = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_RETTSLIB_BASEURL}/package/lists`,
        { headers: { Accept: 'application/json, text/plain, */*', jwt: token } }
    )
    .then(response => {
        return response.data;
    });
};

const getAllProductTypes = () => {
    const token = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_RETTSLIB_BASEURL}/package/types`,
        { headers: { Accept: 'application/json, text/plain, */*', jwt: token } }
    )
    .then(response => {
        return response.data;
    });
};

const getAllVersionsByProduct = id => {
    const token = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_RETTSLIB_BASEURL}/package/versions/${id}`,
        { headers: { Accept: 'application/json, text/plain, */*', jwt: token } }
    )
    .then(response => {
        return response.data;
    });
};

const getAllLangByVersion = (version, product) => {
    const token = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_RETTSLIB_BASEURL}/package/languages/${product}/${version}`,
        { headers: { Accept: 'application/json, text/plain, */*', jwt: token } }
    )
    .then(response => {
        return response.data;
    });
};

const searchEssPackages = (essId, searchPhase) => {
    const token = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_RETTSLIB_BASEURL}/ess/searchpackage/${essId}/${searchPhase}`,
        { headers: { Accept: 'application/json, text/plain, */*', jwt: token } }
    )
    .then(response => {
        return response.data;
    });
};

const getEssDataById = id => {
    const token = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_RETTSLIB_BASEURL}/ess/id/${id}`,
        { headers: { Accept: 'application/json, text/plain, */*', jwt: token } }
    )
    .then(response => {
        return response.data;
    });
};

const getVpPackage = (id, type) => {
    const token = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_RETTSLIB_BASEURL}/vp/vpheaderbytype/${id}/${type}`,
        { headers: { Accept: 'application/json, text/plain, */*', jwt: token } }
    )
    .then(response => {
        return response.data;
    });
};

const getVpTypes = () => {
    const token = localStorage.getItem('token');

    return axios.get(`${process.env.REACT_APP_RETTSLIB_BASEURL}/vp/types`,
        { headers: { Accept: 'application/json, text/plain, */*', jwt: token } }
    )
    .then(response => {
        return response.data;
    });
};

const QuestionServices = {
    addNewQuestion,
    getAllQuestions,
    getQuestionsByFilter,
    getQuestionDetailById,
    deleteQuestion,
    updateQuestion,
    getAllPackages,
    getAllProductTypes,
    getAllVersionsByProduct,
    getAllLangByVersion,
    searchEssPackages,
    getEssDataById,
    getVpPackage,
    getVpTypes,
};

export { QuestionServices };
