/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Cell, Autocomplete, Switch } from 'react-md';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _remove from 'lodash/remove';
import _includes from 'lodash/includes';
import { useStore } from '../../../../../../store.js';

const EssContainer = props => {
    const {
        state: {
            questions: { newQuestion: { selectedEssSymptomsList } }
        },
        dispatch
    } = useStore();

    const { handleSelectedEss, handleEssSearchPhase, essList, selectedEssData, t } = props;
    const setNewQuestionValue = useCallback((data, key) => dispatch({ type: 'setNewQuestionValues', payload: { data, key } }), [dispatch]);

    const handleEssSymptomsSwitch = (val, id) => {
        if (val) {
            setNewQuestionValue(selectedEssSymptomsList.concat(id), 'selectedEssSymptomsList');
        } else {
            const essId = _remove(selectedEssSymptomsList, essid => essid !== id);
            setNewQuestionValue(essId, 'selectedEssSymptomsList');
        }
    };

    return (
        <div className="essSearchBar" >
            <label htmlFor="essSearchBarTab">{t('SEARCH_ESS')}</label>
            <Cell size={12}>
                <Autocomplete
                    id="essSearchBarTab"
                    className="md-cell md-cell--4"
                    onAutocomplete={e => handleSelectedEss(e)}
                    clearOnAutocomplete={true}
                    onChange={e => handleEssSearchPhase(e)}
                    data={_map(essList, ess => ess.searchPhrase)}
                            />
            </Cell>
            {selectedEssData && selectedEssData.id && (
            <div>
                <div className="essHeading">
                    {selectedEssData.number}
.
                    {selectedEssData.packageType}
                </div>
                <div className="divider"/>
                <ul>
                    {_map(selectedEssData.searchPhrases, searchPhrase => (
                        <li key={searchPhrase.id}>{searchPhrase.searchPhrase}</li>
                        ))}
                </ul>
                <div className="divider"/>
                <ul>
                    {_map(selectedEssData.links, links => (
                        <li key={links.id}>{links.description}</li>
                        ))}
                </ul>
                <div className="divider"/>
                {_map(_sortBy(selectedEssData.symptoms, ['essRisklevel']), symptoms => (
                    <Switch
                        className={`risklevel_${symptoms.essRisklevel}`}
                        key={symptoms.id}
                        id={symptoms.id}
                        type="switch"
                        checked={_includes(selectedEssSymptomsList, symptoms.id)}
                        onChange={e => handleEssSymptomsSwitch(e, symptoms.id)}
                        label={symptoms.description}
                        name={symptoms.description}
                          />
                        ))}
                {selectedEssData.preActions.length ? (
                    <div>
                        <div className="divider"/>
                        <div className="title">
                                Pre-actions
                        </div>
                        <ul>
                            {_map(selectedEssData.preActions, preActions => (
                                <li key={preActions.id}>{preActions.description}</li>
                                ))}
                        </ul>
                    </div>
                    ) : null}
                <div className="divider"/>
                <div className="title">
                    {`${selectedEssData.packageType} fact box`}
                </div>
                <div className="infos">
                    {_map(selectedEssData.infos, infos => (
                        <div key={infos.id}>{infos.text}</div>
                        ))}
                </div>
                <div className="divider"/>
            </div>
            )}
        </div>
    );
};

export default withTranslation()(EssContainer);
