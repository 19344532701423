import axios from 'axios';

const getAllAdminList = () => {
    return axios.get(`${process.env.REACT_APP_API_BASEURL}/adminList`,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const addNewAdmin = (username, adminName) => {
    return axios.post(`${process.env.REACT_APP_API_BASEURL}/adminList`,
        { username, adminName },
        { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const deleteAdmin = id => {
    return axios.delete(`${process.env.REACT_APP_API_BASEURL}/adminList/${id}`,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const AdminServices = {
    getAllAdminList,
    addNewAdmin,
    deleteAdmin
};

export { AdminServices };
