/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { FontIcon } from 'react-md';

const Category = props => {
    const { t, handleSection } = props;
    const [addQuestionClass, setAddQuestionClass] = useState('category active');
    const [ManageQuestionClass, setManageQuestionClass] = useState('category');

    const handleManageQuiz = () => {
        setAddQuestionClass('category');
        setManageQuestionClass('category active');
        handleSection(2);
    };

    const handleAddQuiz = () => {
        setAddQuestionClass('category active');
        setManageQuestionClass('category');
        handleSection(1);
    };

    return (
        <div className="categoryList">
            <div className={addQuestionClass} onClick={handleAddQuiz}>
                <FontIcon>playlist_add</FontIcon>
                {t('ADD_QUIZ')}
            </div>
            <div className={ManageQuestionClass} onClick={handleManageQuiz}>
                <FontIcon>playlist_add_check</FontIcon>
                {t('MANAGE_QUIZ')}
            </div>
        </div>
    );
};

export default withTranslation()(Category);
