/* eslint-disable react/prop-types */
import React from 'react';
import { withTranslation } from 'react-i18next';
import _map from 'lodash/map';
import './BreadCrums.scss';

const BreadCrums = props => {
    const { breadCrumsHeading, step, studentResult, t } = props;
    const { selectedVpPrioValue, selectedEssPrioValue, selectedFinalPrioValue } = studentResult;

    const renderBreadCrum = (breadcrum, i) => {
        const currentCss = breadcrum.step <= step ? 'active' : '';
        let riskLevelCss = '';

        if (breadcrum.step === 2) {
            riskLevelCss = selectedVpPrioValue.substr(selectedVpPrioValue.length - 11);
        }
        if (breadcrum.step === 3) {
            riskLevelCss = selectedEssPrioValue.substr(selectedEssPrioValue.length - 11);
        }
        if (breadcrum.step === 4) {
            riskLevelCss = selectedFinalPrioValue.substr(selectedFinalPrioValue.length - 11);
        }

        return (
            <li key={i} className={`${currentCss} ${riskLevelCss}`}>
                <span>{breadcrum.step}</span>
                <div className="breadCrum_name">{t(breadcrum.name)}</div>
            </li>
        );
    };

    return (
        <div className="BreadCrums">
            <div className="wrapper-progressBar">
                <ul className="progressBar">
                    {_map(breadCrumsHeading, (breadcrum, i) => renderBreadCrum(breadcrum, i))}
                </ul>
            </div>
        </div>
    );
};

export default withTranslation()(BreadCrums);
