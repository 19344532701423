import React from 'react';
import { ListItem } from 'react-md';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { getLanguageCode, getLanguageName, maxOrDefault } from './utils';
import FileRow from './FileRow';
import FileIconAvatar from './FileIconAvatar';

const ResourceItem = props => {
    const {
        resource,
        key,
        groupName
    } = props;

    const lang = getLanguageCode();

    const fileMatchesLanguage = (file, language) => {
        return file.languages.indexOf(language) >= 0;
    };

    const getMatchingLanguageFiles = (resource, language) => {
        if (resource.type === 'FileGroup') {
            return resource.files.filter(file => fileMatchesLanguage(file, language));
        }

        return [];
    };

    /**
     * Sorts resource files in a FileGroup according where the first one is primary and the secondaries in order of
     * relevance. (meant for use as comparator in sorting functions)
     * @param first {{years: [number], languages: [string]}} The first file to compare.
     * @param second {{years: [number], languages: [string]}} The second file to compare.
     */
    const sortResourcesFiles = (first, second) => {
        return maxOrDefault(second.years) - maxOrDefault(first.years);
    };

    /**
     * Partitions resource files in a group into the primary file that should be shown in the list. And the alternative
     * files that should be shown in the dropdown.
     * @param resource The Resource of type FileGroup to partition files for.
     * @param language The language to filter files by.
     */
    const partitionResourceFiles = (resource, language) => {
        if (resource.type === 'FileGroup') {
            const candidates = getMatchingLanguageFiles(resource, language);
            candidates.sort(sortResourcesFiles);

            const primary = candidates[0];

            const alternatives = candidates.filter(file => file !== primary);

            return [primary, alternatives];
        }

        return [null, []];
    };

    /**
     * Element to show when no files match the selected language.
     * @returns {JSX.Element}
     */
    const noPrimaryItemRow = () => {
        return (
            <ListItem
                key={key}
                primaryText={i18next.t('RESOURCE_FILE_NOT_AVAILABLE')}
                secondaryText={`${groupName}/${resource.id}`}
                renderChildrenOutside
                leftAvatar={<FileIconAvatar extension={'missing'}/>}
                threeLines/>
        );
    };

    const getResourceRow = () => {
        switch (resource.type) {
            case 'FileGroup':
                const [primary, alternatives] = partitionResourceFiles(resource, lang);
                const alternativeRows = alternatives ? alternatives.map(alternateItem => (
                    <FileRow key={alternateItem.id} file={alternateItem}/>
                )) : null;

                return primary ? (
                    <FileRow key={key} file={primary}>
                        {alternativeRows}
                    </FileRow>
                ) : (
                    noPrimaryItemRow()
                );
            case 'File':
                return <FileRow key={key} file={resource.file}/>;
            case 'Link':
                return <FileRow key={key} file={resource.file}/>;
            default:
                return <h1>Unsupported type {resource.type}</h1>;
        }
    };

    return (
        getResourceRow()
    );
};

export default withTranslation()(ResourceItem);
