import axios from 'axios';

const getAllResources = () => {
    return axios.get(`${process.env.REACT_APP_API_BASEURL}/resources`,
        { headers: { accessToken: localStorage.getItem('accessToken') } })
        .then(response => {
            return response.data;
        });
};

const resourceServices = {
    getAllResources
};

export { resourceServices };
