
import React, { useState, useCallback } from 'react';
import {
    Button,
    DataTable,
    TableHeader,
    TableBody,
    TableRow,
    TableColumn,
    TablePagination } from 'react-md';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import { useStore } from '../../../../../../store.js';
import { middlewares } from '../../../../../../middlewares';
import QuizForm from './QuizForm';
import FilterSection from './FilterSection';
import './AddQuiz.scss';

const AddQuiz = () => {
    const [currectionSection, setCurrentSection] = useState(1);

    const handleSection = section => {
        setCurrentSection(section);
    };

    const renderSections = () => {
        const mixins = {
            handleSection,
        };

        let section = <QuizForm {...mixins}/>;

        switch (currectionSection) {
            case 1:
                section = <QuizForm {...mixins}/>;
                break;

            case 2:
                section = <FilterSection {...mixins}/>;
                break;

            case 3:
                section = <FilteredQuestionList {...mixins}/>;
                break;

            default:
                section = <QuizForm {...mixins}/>;
                break;
        }

        return section;
    };

return (
    <div className="AddQuizContainer">
        <div className="innerContainer">
            {renderSections()}
        </div>
    </div>
    );
};

const FilteredQuestionList = props => {
    const {
        state: {
          questions: { filteredQuestions },
          user: { userDetails },
          quiz: { newQuiz, newQuiz: { selectedQuestions } }
        },
        dispatch
    } = useStore();


    const [rows] = useState(filteredQuestions.length);
    const [sno, setSno] = useState(0);
    const [slicedData, setSlicedData] = useState(filteredQuestions.slice(0, 10));

    const handlePagination = (start, rowsPerPage) => {
        setSno(start / 10);
        setSlicedData(filteredQuestions.slice(start, start + rowsPerPage));
    };

    const alertActions = useCallback(data => dispatch({ type: 'alertPopup', payload: data }), [dispatch]);
    const resetQuiz = useCallback(() => dispatch({ type: 'resetQuiz', payload: {} }), [dispatch]);
    const handleSelectedQuestions = useCallback(data => dispatch({ type: 'setSelectedQuestions', payload: data }), [dispatch]);
    const clearSelectedQuestions = useCallback(() => dispatch({ type: 'clearSelectedQuesion' }), [dispatch]);
    const setQuizList = useCallback(data => dispatch({ type: 'setQuizList', payload: data }), [dispatch]);

    const handleCreateBtn = () => {
        middlewares.createQuiz({ newQuiz, name: userDetails.username })
        .then(data => {
            const actions = {
                showConfirmButton: true,
                msg: data.msg,
                isShowBtns: false
            };
            setQuizList(data.data);
            props.handleSection(1);
            alertActions({ displayAlert: true, alertActions: actions });
            setTimeout(() => alertActions({ displayAlert: false, alertActions: {} }), 1000);
        });
        props.handleSection(1);
        resetQuiz();
    };

    const handleBackBtn = page => {
        clearSelectedQuestions([]);
        props.handleSection(page);
    };

    const handleCheckboxClick = item => {
        handleSelectedQuestions(item);
    };

    const tableHeader = () => {
        return (
            <TableRow>
                <TableColumn>S.No</TableColumn>
                <TableColumn>Title</TableColumn>
                <TableColumn>Tags</TableColumn>
                <TableColumn>Situation</TableColumn>
                <TableColumn>Created By</TableColumn>
                <TableColumn>Last updated by</TableColumn>
                <TableColumn>Preview</TableColumn>
            </TableRow>
        );
    };

    const tableProps = newQuiz.type === 'manual' ? {
        baseId: 'simple-selectable-table',
        indeterminate: true
    } : { plain: true };

    // const actions = [];
    // actions.push({ secondary: true, children: 'Cancel', onClick: ()=>showModal(false) });
    // actions.push(<Button flat primary onClick={()=>showModal(false)}>Confirm</Button>);

    return (
        <div className="container">
            {/* <FilterModal visible={modalShow} onHide={showModal} filterBy={filterBy} applyFilter={applyFilter} /> */}
            <DataTable {...tableProps}>
                <TableHeader>
                    {tableHeader()}
                </TableHeader>
                <TableBody>
                    {_map(slicedData, (item, i) => (
                        <TableRow
                            key={item._id}
                            selectable={newQuiz.type === 'manual'}
                            selected={_includes(selectedQuestions, item._id)}
                            onCheckboxClick={() => handleCheckboxClick(item._id)}>
                            <TableColumn>{i + (sno * 10) + 1}</TableColumn>
                            <TableColumn>{item.title}</TableColumn>
                            <TableColumn>
                                {
                                    _map(item.tags, (tag, i) => (
                                        <div key={i} className="tagList">
                                            {tag}
                                        </div>
                                    ))
                                }
                            </TableColumn>
                            <TableColumn>
                                <div className="pSituationText">{item.pSituation}</div>
                            </TableColumn>
                            <TableColumn>{item.createdBy}</TableColumn>
                            <TableColumn>{item.lastUpdatedBy}</TableColumn>
                            <TableColumn>
                                <Button icon primary onClick={() => {}} iconChildren="visibility" />
                            </TableColumn>
                        </TableRow>
                    ))}
                </TableBody>
            </DataTable>
            <div className="footer">
                <div className="pagination">
                    <DataTable baseId="simple-pagination">
                        <TablePagination
                            rows={rows}
                            defaultPage={sno + 1}
                            onPagination={handlePagination} />
                    </DataTable>
                    {
                        newQuiz.type === 'manual' && (
                        <span>
                            Selected Questions:
                            {' '}
                            {selectedQuestions.length}
                        </span>
                        )
                    }
                    <div>
                        {/* <Button flat secondary onClick={newQuiz.type === 'manual' ? ()=>handleBackBtn(1): ()=>handleBackBtn(2)} iconChildren="navigate_before">Back</Button> */}
                        <Button
                            flat
                            secondary
                            onClick={() => handleBackBtn(2)}
                            iconChildren="navigate_before">
                            Back
                        </Button>
                        <Button
                            flat
                            disabled={selectedQuestions.length <= 0}
                            primary
                            onClick={handleCreateBtn}>
                            Create Quiz
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddQuiz;
