/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
    TextField
  } from 'react-md';

const AddTagForm = props => {
    return (
        <div className="md-grid textField">
            <label htmlFor="tags">{'Tag Name'}</label>
            <TextField
                id="tags"
                // label="Tag Name"
                type="text"
                onChange={props.handleNewTag}
                className="md-cell md-cell--12" />
            <div className="response_msg">
                {props.responseMsg}
            </div>
        </div>
    );
};

export default AddTagForm;
