import React, { useState } from 'react';
import { FileInput, FontIcon, TextField, Button, SelectField } from 'react-md';
import { withTranslation } from 'react-i18next';
import { middlewares } from '../../../../middlewares';
import Constants from '../../../../Utils/Constants';

const AddNewCourse = props => {
    const [file, setFile] = useState('');
    const [Img, setImg] = useState('');
    const [fileTitle, setFileTitle] = useState('');
    const [msg, setMsg] = useState('');
    const [error, setError] = useState('');
    const { t } = props;
    const [selectedLang, setSelectedLang] = useState('');

    const languageList = Constants.getQuizLangList();

    const uploadFile = () => {
        setMsg('Uploading...');
        setError('');
        const formData = new FormData();
        formData.set('title', fileTitle);
        formData.set('language', selectedLang);
        formData.append('myImage', Img);
        formData.append('myFile', file);

        const uplaodFile = middlewares.uploadNewCourse(formData);
        uplaodFile.then(res => {
            if ((res.status === 200) && (res.data.file === 'saved')) {
                setFileTitle('');
                setFile('');
                setImg('');
                setSelectedLang('');
                setMsg(res.data.msg);
                props.setCourseList(res.data.newCourseList);
            } else {
                setMsg('');
                setError(res.data.err);
            }
        });
    };

    const handleImage = (e, e1) => {
        console.log(e1, '===', e);
        setMsg('');
        setError('');
        if (e) {
            if (e.size > 50000) {
                setError(t('MAX_IMG_SIZE_EXCEED_ERR'));
                setImg('');
            } else {
                setImg(e);
            }
        } else {
            setImg('');
        }
    };

    const handleFile = e => {
        setMsg('');
        setError('');
        if (e) {
            if (e.size > 250000000) {
                setError('MAX_FILE_SIZE_EXCEED_ERR');
                setFile('');
            } else {
                setFile(e);
            }
        } else {
            setFile('');
        }
    };

    const handleTitle = e => {
        setFileTitle(e);
        setMsg('');
        setError('');
    };

    const handleLanguageOnChange = e => {
        console.log(e);
        setSelectedLang(e);
    };


    return (
        <div className="addCourseContainer">
            <h1 className="categoryHeading text-center">{t('ADD_NEW_COURSE')}</h1>
            <div className="md-grid grid-example">
                <label htmlFor="title">{t('COURSE_TITLE')}</label>
                <TextField
                    id="title"
                    type="text"
                    value={fileTitle}
                    onChange={handleTitle}
                    className="md-cell md-cell--12" />
            </div>
            <div className="md-grid grid-example">
                <label htmlFor="languageList">{t('SELECT_QUIZ_BY_LANGUAGE')}</label>
                <SelectField
                    id="languageList"
                    className="quizlangSelectBox md-cell md-cell--12"
                    onChange={handleLanguageOnChange}
                    value={selectedLang}
                    menuItems={languageList}
                    position={SelectField.Positions.BELOW}
                    />
            </div>
            <div className="md-grid grid-example">
                <div className="md-cell md-cell--12 selectBtn">
                    <FileInput
                        id="image-input-4"
                        name="image"
                        raised="true"
                        primary
                        accept="image/*"
                        allowDuplicates
                        label="select course Image"
                        onChange={handleImage}
                        />
                    <span>{t('MAX_IMG_SIZE_MSG')}</span>
                </div>
                {
                    Img.name && (
                    <div className="file_detail md-cell md-cell--12">
                        {`${t('SELECTED_FILE')}: ${Img.name}`}
                        <FontIcon onClick={() => setImg('')}>delete</FontIcon>
                    </div>
                    )
                }
            </div>
            <div className="md-grid grid-example">
                <div className="md-cell md-cell--12 selectBtn">
                    <FileInput
                        id="file-input-4"
                        name="zip"
                        raised="true"
                        accept=".zip"
                        primary
                        allowDuplicates
                        label="select course file"
                        onChange={handleFile}
                        />
                    <span>{t('MAX_FILE_SIZE_MSG')}</span>
                </div>
                {
                    file.name && (
                    <div className="file_detail md-cell md-cell--12">
                        {`${t('SELECTED_FILE')}: ${file.name}`}
                        <FontIcon onClick={() => setFile('')}>delete</FontIcon>
                    </div>
                    )
                }
            </div>
            <Button
                raised
                disabled={file === '' || Img === '' || fileTitle === '' || selectedLang === ''}
                onClick={uploadFile}
                primary >
                <FontIcon>cloud_upload</FontIcon>
                {t('UPLOAD')}
            </Button>
            {
                msg && (
                <div className="msg">
                    {msg}
                </div>
                )
            }
            {
                error && (
                <div className="error">
                    {error}
                </div>
                )
            }
        </div>
        );
    };

export default withTranslation()(AddNewCourse);
