import React, { Component } from 'react';
import './footer.scss';

export default class Footer extends Component {
    render() {
        return (
            <div className="footerContainer">
                <a href="https://predicare.se/" rel="noopener noreferrer" target="_blank">&copy; {new Date().getFullYear()} predicare.se</a>
            </div>
        );
    }
}
