// import _map from 'lodash/map';

const placeholder = document.createElement('li');
placeholder.className = 'placeholder';

export const courseInitialState = {
    course: {
        courseList: [],
    }
};

export const courseActions = {
    setCourseList: (state, payload) => {
        return {
            course: {
                ...state.course,
                // courseList: _map(payload, (course, i) => ({ ...course, courseId: course.id, id: i }))
                courseList: payload
            }
        };
    }
};
