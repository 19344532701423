
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _includes from 'lodash/includes';
import _filter from 'lodash/filter';
import { Button } from 'react-md';
import VpContainer from './VpContainer';
import EssData from './EssData';
import Facts from './Facts';
import FinalPrio from './FinalPrio';
import BreadCrums from '../BreadCrums';
import { middlewares } from '../../../../../middlewares';
import './QuestionLayout.scss';

const QuestionLayout = props => {
const { t, questionId, handleCaseId, currentCase, totalCases } = props;

    const [questionDetail, setQuestionDetail] = useState([]);
    const [step, setStep] = useState(1);
    const [alertMsg, setAlertMsg] = useState('');
    const [isVisibleError, setVisibleError] = useState('');
    const [studentResult, setStudentResult] = useState({
        selectedVpPrioValue: '',
        selectedEssPrioValue: '',
        selectedEssData: {},
        selectedEssSymptomsList: [],
        selectedFinalPrioValue: '',
    });

    const breadCrumsHeading = [
        { step: 1, name: 'ANAMNESIS' },
        { step: 2, name: 'VITAL_SIGNS' },
        { step: 3, name: 'ESS' },
        { step: 4, name: 'FINAL_PRIO' }];

    useEffect(() => {
        const fetchData = async () => {
            const questionDetail = await middlewares.getQuestionDetailById(questionId)
            .then(question => question);
            setQuestionDetail(questionDetail[0]);
        };
        fetchData();
    }, [questionId]);

    const handleStudentSelectedValue = val => {
        setStudentResult({ ...studentResult, ...val });
    };

    const handleErrorMsg = msg => {
        setVisibleError(true);
        setAlertMsg(msg);
    };

    const closeErrorMsg = () => {
        setVisibleError(false);
        setAlertMsg('');
    };

    const checkEssSymptomsList = (actualSymptoms, selectedSymptoms) => {
        const result = _includes(_map(actualSymptoms, symptoms => _includes(selectedSymptoms, symptoms)), false);

        return (!result && (actualSymptoms.length === selectedSymptoms.length)) ? true : false;
    };

    const handleStep = selectedStep => {
        closeErrorMsg();
        const vpResult = _filter(questionDetail.results, { key: 'Prio vital signs' })[0];
        const essResult = _filter(questionDetail.results, { key: 'Prio Ess' })[0];
        const finalResult = _filter(questionDetail.results, { key: 'Final prio' })[0];
        if (selectedStep === 0) {
            handleCaseId(-1);
            setStudentResult({
                selectedVpPrioValue: '',
                selectedEssData: {},
                selectedEssSymptomsList: [],
                selectedFinalPrioValue: '',
                selectedEssPrioValue: ''
            });
        }

        switch (selectedStep) {
            case 1:
                    setStep(selectedStep);
                break;
            case 2:
                    setStep(selectedStep);
                break;
            case 3:
                if (studentResult.selectedVpPrioValue && (studentResult.selectedVpPrioValue === vpResult.value)) {
                    setStep(selectedStep);
                } else {
                    handleErrorMsg(t('CHOOSE_CORRECT_VP'));
                }
                break;
            case 4:
                if ((!_isEmpty(studentResult.selectedEssData) && studentResult.selectedEssSymptomsList.length)
                    && checkEssSymptomsList(questionDetail.selectedEssSymptomsList, studentResult.selectedEssSymptomsList)
                    && (questionDetail.selectedEssData.id === studentResult.selectedEssData.id)) {
                        if (studentResult.selectedEssPrioValue && (studentResult.selectedEssPrioValue === essResult.value)) {
                            setStep(selectedStep);
                        } else {
                            handleErrorMsg(t('CHOOSE_CORRECT_ESS_PRIO'));
                        }
                        break;
                    } else {
                        if (studentResult.selectedEssPrioValue && (studentResult.selectedEssPrioValue === essResult.value)) {
                            handleErrorMsg(t('CHOOSE_CORRECT_ESS_SYMPTOMS'));
                        } else {
                            handleErrorMsg(`${t('CHOOSE_CORRECT_ESS_SYMPTOMS')} and ${t('CHOOSE_CORRECT_ESS_PRIO')}`);
                        }

                        break;
                    }
            case 5:
                if (finalResult.value !== studentResult.selectedFinalPrioValue) {
                        handleErrorMsg(t('PRIO_NOT_MATCHED'));
                } else {
                    setStep(selectedStep);
                }
                break;
            default:
                break;
            }

        // if(selectedStep === 1
        //     || selectedStep === 2
        //     || (selectedStep === 3 && studentResult.selectedVpPrioValue)
        //     || (selectedStep === 4
        //         && !_isEmpty(studentResult.selectedEssData)
        //         && studentResult.selectedEssSymptomsList.length))
        //         {
        //             setStep(selectedStep);
        //         }
    };

    const handleSubmit = () => {
        handleCaseId(1);
        setStep(1);
        setStudentResult({
            selectedVpPrioValue: '',
            selectedEssData: {},
            selectedEssSymptomsList: [],
            selectedFinalPrioValue: '',
            selectedEssPrioValue: ''
        });
    };

    const renderLeftBtn = () => {
        return (!(currentCase === 0 && step === 1)
        ? <Button flat secondary onClick={() => handleStep(step - 1)}>{t('BACK')}</Button>
        : <div/>);
    };

    const renderRightBtn = () => {
        return (
            <Button flat secondary onClick={() => (step < 5 ? handleStep(step + 1) : handleSubmit())}>
                {step === 5 ? t('FINISH') : t('NEXT')}
            </Button>
);
    };

    const handleContinueLater = () => {
        // eslint-disable-next-line no-alert
        const a = confirm(t('CONFIRM_LEAVE_QUIZ'));
        a && props.handleContinueLater(currentCase);
    };

    return (
        <div >
            {!_isEmpty(questionDetail) && (
            <div className="QuizContainer QuizContainerWithbreadCrum">
                <div className="quizHeader">
                    <div className="caseHeading">
                        {t('CASE')}
                        {' '}
:
                        {' '}
                        {currentCase + 1}
/
                        {totalCases}
                    </div>
                    <div className="continue_later_btn_container">
                        <Button
                            onClick={handleContinueLater}
                            className="continue_later_btn"
                            raised>
                            {t('CONTINUE_LATER')}
                        </Button>
                    </div>
                </div>
                {step === 1 && (
                    <div className="wrapper">
                        <div className="innerContainer">
                            <div className="questionCategoryHeading">{t('SITUATION')}</div>
                            <div className="questionSituation">{questionDetail.pSituation}</div>
                        </div>
                        <div className="stepsFooter">
                            {renderLeftBtn()}
                            {renderRightBtn()}
                        </div>
                    </div>
                )}
                {
                    step === 2 && (
                    <VpContainer
                        vpResult={_filter(questionDetail.results, { key: 'Prio vital signs' })[0]}
                        pConditions={questionDetail.pConditions}
                        vpPackageId={questionDetail.vpPackageId}
                        handleStudentSelectedValue={handleStudentSelectedValue}
                        studentResult={studentResult}
                        renderLeftBtn={renderLeftBtn()}
                        isVisibleError={isVisibleError}
                        alertMsg={alertMsg}
                        closeErrorMsg={closeErrorMsg}
                        renderRightBtn={renderRightBtn()}
                        />
)
                }
                {
                    step === 3 && (
                    <EssData
                        pSymptoms={questionDetail.pSymptoms}
                        essPackageId={questionDetail.essPackageId}
                        essResult={_filter(questionDetail.results, { key: 'Prio Ess' })[0]}
                        handleErrorMsg={handleErrorMsg}
                        handleStudentSelectedValue={handleStudentSelectedValue}
                        selectedEssId={questionDetail.selectedEssData.id}
                        selectedEssSymptomsList={questionDetail.selectedEssSymptomsList}
                        studentResult={studentResult}
                        isVisibleError={isVisibleError}
                        alertMsg={alertMsg}
                        closeErrorMsg={closeErrorMsg}
                        renderLeftBtn={renderLeftBtn()}
                        renderRightBtn={renderRightBtn()}/>
)
                }
                {
                    step === 4 && (
                    <FinalPrio
                        studentResult={studentResult}
                        essResult={_filter(questionDetail.results, { key: 'Prio Ess' })[0]}
                        vpResult={_filter(questionDetail.results, { key: 'Prio vital signs' })[0]}
                        finalResult={_filter(questionDetail.results, { key: 'Final prio' })[0]}
                        handleStudentSelectedValue={handleStudentSelectedValue}
                        renderLeftBtn={renderLeftBtn()}
                        isVisibleError={isVisibleError}
                        alertMsg={alertMsg}
                        closeErrorMsg={closeErrorMsg}
                        renderRightBtn={renderRightBtn()}/>
)
                }
                {
                    step === 5 && (
                    <Facts
                        studentResult={studentResult}
                        handleStudentSelectedValue={handleStudentSelectedValue}
                        diagnosis={_filter(questionDetail.results, { key: 'Diagnosis' })[0]}
                        process={_filter(questionDetail.results, { key: 'Process' })[0]}
                        facts={_filter(questionDetail.results, { key: 'Facts and course of events' })[0]}
                        diffDiagnosis={_filter(questionDetail.results, { key: 'Differential diagnosis' })[0]}
                        renderLeftBtn={renderLeftBtn()}
                        renderRightBtn={renderRightBtn()}/>
)
                }
                <BreadCrums step={step} breadCrumsHeading={breadCrumsHeading} studentResult={studentResult}/>
            </div>
            )}
        </div>
    );
};

export default withTranslation()(QuestionLayout);
