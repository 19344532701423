/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { TabsContainer, Tabs, Tab } from 'react-md';
import { useStore } from '../../../store.js';
import { middlewares } from '../../../middlewares';
import Questions from './admin/Questions';
import { Quiz as AdminQuiz } from './admin/Quiz';
import Quiz from './student/Quiz';

import './dashboard.scss';

const Dashboard = props => {
    const { t } = props;
    const {
        state: {
            user: { userRole, view }
        },
        dispatch
    } = useStore();

    const setTags = useCallback(data => dispatch({ type: 'setTags', payload: data }), [dispatch]);
    const setQuestions = useCallback(data => dispatch({ type: 'setQuestions', payload: data }), [dispatch]);
    const setQuizList = useCallback(data => dispatch({ type: 'setQuizList', payload: data }), [dispatch]);
    const setNewQuestionValues = useCallback((data, key) => dispatch({ type: 'setNewQuestionValues', payload: { data, key } }), [dispatch]);
    const setProductList = useCallback(data => dispatch({ type: 'setProductList', payload: data }), [dispatch]);
    const setPackagesList = useCallback(data => dispatch({ type: 'setPackagesList', payload: data }), [dispatch]);
    const login = useCallback(data => dispatch({ type: 'login', payload: data }), [dispatch]);
    const logout = useCallback(data => dispatch({ type: 'logout', payload: data }), [dispatch]);
    const setLogo = useCallback(data => dispatch({ type: 'setLogo', payload: data }), [dispatch]);

    useEffect(() => {
        setLogo('RETTSSim');

        const fetchTags = async () => {
            const tagList = await middlewares.getAllTags()
            .then(list => list);
            setTags(tagList);
        };
        const fetchQuestions = async () => {
            const QuesList = await middlewares.getAllQuestions()
            .then(list => list);
            setQuestions(QuesList);
        };
        const fetchQuiz = async () => {
            const QuizList = await middlewares.getAllQuiz()
            .then(list => list);
            setQuizList(QuizList);
        };
        const fetchAllPackages = async () => {
            const lists = await middlewares.getAllPackages()
            .then(list => list);
            setPackagesList(lists);
        };
        const fetchAllProductTypes = async () => {
            const types = await middlewares.getAllProductTypes()
            .then(list => list);
            setProductList(types);
        };

        const fetchData = () => {
            fetchTags();
            fetchQuestions();
            fetchQuiz();
            fetchAllPackages();
            fetchAllProductTypes();
        };

        const getUserData = async () => {
           await middlewares.getUserData()
            .then(data => {
                data.logo = 'RETTSSim';
                data.path = `/rettssim/${data.userRole}/dashboard`;
                data.isUserLoggedIn ? login(data) : logout(data);
                fetchData();
            });
        };

        localStorage.getItem('isRefreshed') === 'true' ? getUserData() : fetchData();
    }, [login, logout, setLogo, setNewQuestionValues, setPackagesList, setProductList, setQuestions, setQuizList, setTags]);

    const renderAdminView = () => {
        return (
            <TabsContainer defaultTabIndex={0} id="adminView" panelClassName="md-grid" colored>
                <Tabs tabId="simple-tab">
                    <Tab label={t('QUESTION_BANKS')}>
                        <Questions/>
                    </Tab>
                    <Tab label={t('QUIZ')}>
                        <AdminQuiz/>
                    </Tab>
                </Tabs>
            </TabsContainer>
        );
    };

    const renderStudentView = () => {
        return (
            <Quiz/>
        );
    };

    return (
        <div>
            {userRole === 'admin'
                && (
                <div>
                    {view === 'admin' ? renderAdminView() : renderStudentView()}
                </div>
            )}
            {userRole === 'student'
                && renderStudentView()}
        </div>
    );
};

export default withTranslation()(Dashboard);
