import React from 'react';
import ReactToPrint from 'react-to-print';
import { Button, TextField } from 'react-md';
import DiplomaContent from './DiplomaContent';

class Diploma extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            isVisible: true,
            hasValidCourseName: false,
        };
        this.startIdentifier = 'courses/';
        this.endIdentifier = '_';
        this.url = document.referrer;
    }

    handleInput (e) {
        this.setState({
            name: e
        });
    }

    beforePrint () {
        this.setState({
            isVisible: true
        });
    }

    getCourseName () {
        let courseName = decodeURI(this.url.slice(this.url.search(this.startIdentifier) + this.startIdentifier.length, this.url.search(this.endIdentifier)));

        if (courseName.includes('retts')) {
            courseName = courseName.replace('retts', 'RETTS');
        }

        return courseName;
    }

    componentDidMount () {
        if (this.url.search(this.startIdentifier) !== -1 || this.url.search(this.endIdentifier) !== -1) {
            this.setState({
                hasValidCourseName: true
            });
        }
    }

    render() {
        const { hasValidCourseName } = this.state;
        const { name, isVisible } = this.state;
        const courseName = this.getCourseName();

    return (
        <div className="iframeContainer">
            <div>
                <TextField
                    id="name"
                    type="text"
                    onChange={e => { this.handleInput(e); }} />
            </div>
            <DiplomaContent
                ref={el => { this.componentRef = el; }}
                name={name}
                courseName={courseName}
                isVisible={isVisible}/>
            {hasValidCourseName
                && (
                    <ReactToPrint
                        onAfterPrint={() => { this.setState({ isVisible: false }); }}
                        onBeforeGetContent={() => { this.beforePrint(); }}
                        trigger={() => {
                            return <Button raised primary>Ok</Button>;
                        }}
                        content={() => this.componentRef}
                    />
                )
            }
            {!hasValidCourseName
                && (
                    <div>
                        ERROR. Cannot read course name
                    </div>
                )
            }
        </div>
            );
        }
    }

export default Diploma;
