import { userServices } from './user';
import { AdminServices } from './adminDetails';
import { TagsServices } from './tags';
import { QuestionServices } from './questions';
import { QuizServices } from './quiz';
import { courseServices } from './courses';

export const middlewares = {
    ...userServices,
    ...AdminServices,
    ...TagsServices,
    ...QuestionServices,
    ...QuizServices,
    ...courseServices
};
