/* eslint-disable react/prop-types */
import React, { useEffect, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { TabsContainer, Tabs, Tab } from 'react-md';
import { useStore } from '../../../store.js';
import { middlewares } from '../../../middlewares';
import AddNewCourse from './Admin/AddNewCourse';
import CourseList from './Common/CourseList';

import './eLearningStyles.scss';
import ResourcesTab from './Resources/ResourcesTab';

const ELearningDashboard = props => {
    const { t } = props;
    const {
        state: {
            user: { userRole, view }
        },
        dispatch
    } = useStore();

    const login = useCallback(data => dispatch({ type: 'login', payload: data }), [dispatch]);
    const logout = useCallback(data => dispatch({ type: 'logout', payload: data }), [dispatch]);
    const setLogo = useCallback(data => dispatch({ type: 'setLogo', payload: data }), [dispatch]);
    const setCourseList = useCallback(data => dispatch({ type: 'setCourseList', payload: data }), [dispatch]);
    const alertActions = useCallback(data => dispatch({ type: 'alertPopup', payload: data }), [dispatch]);

    const fetchCourseList = async () => {
        await middlewares.getAllActiveCourses()
        .then(list => {
            setCourseList(list);
        });
    };

    useEffect(() => {
        setLogo('RETTSELearning');

        const getUserData = async () => {
           await middlewares.getUserData()
            .then(data => {
                data.logo = 'RETTSELearning';
                data.path = `/elearning/${data.userRole}/dashboard`;
                data.isUserLoggedIn ? login(data) : logout(data);
                fetchCourseList();
            });
        };

        localStorage.getItem('isRefreshed') === 'true' && getUserData();
    }, [login, logout, setLogo, setCourseList]);

    const hideAlert = () => {
        alertActions({ displayAlert: false, alertActions: {} });
    };

    const deleteCourse = id => {
        middlewares.deleteCourse(id).then(() => {
            fetchCourseList();
            hideAlert();
        });
    };

    const editCourse = id => {
        console.log('===edit', id);
    };

    const showDeleteAlert = id => {
        const actions = {
            showConfirmButton: true,
            msg: t('DELETE_CONFIRM_MSG'),
            okBtn: deleteCourse,
            cancelBtn: hideAlert,
            callBackProps: id,
        };
        alertActions({ displayAlert: true, alertActions: actions });
    };

    const getSelectedCourse = async id => {
        const data = await middlewares.getSelectedCourse(id);
        window.open(`${process.env.REACT_APP_COURSE_FILE_PATH}/${data.folderName}/index.html?user=${localStorage.getItem('accessToken')}&baseurl=${process.env.REACT_APP_API_BASEURL}&courseid=${data.id}${data.url}`, '_blank');
    };

    const adminViewsVisible = () => view === 'admin' && userRole === 'admin';

    const courseListTab = () => (
        <Tab label={t('COURSE_LIST')}>
            <CourseList
                setCourseList={setCourseList}
                deleteCourse={showDeleteAlert}
                editCourse={editCourse}
                getSelectedCourse={getSelectedCourse}
            />
        </Tab>
    );

    const resourcesTab = () => (
        <Tab label={t('RESOURCE_TAB')}>
            <ResourcesTab />
        </Tab>
    );

    const adminViewTab = () => {
        if (adminViewsVisible()) {
            return (
                <Tab label={t('ADD_COURSE')}>
                    <AddNewCourse setCourseList={setCourseList}/>
                </Tab>
            );
        }

        return null;
    };

    return (
        <TabsContainer defaultTabIndex={0} id="adminView" panelClassName="md-grid tab-panel" colored style={{ overflow: 'auto' }}>
            <Tabs tabId="simple-tab">
                {courseListTab()}
                {resourcesTab()}
                {adminViewTab()}
            </Tabs>
        </TabsContainer>
    );
};

export default withTranslation()(ELearningDashboard);
