import React, { useState, useCallback, useEffect } from 'react';
import { Button, Paper, TextField } from 'react-md';
import { useStore } from '../../../store.js';
import { middlewares } from '../../../middlewares';
import './login.scss';

const Login = () => {
    const {
        state: {
            user: { loginMsg }
        },
        dispatch
    } = useStore();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');

    const login = useCallback(data => dispatch({ type: 'login', payload: data }), [dispatch]);
    const logout = useCallback(data => dispatch({ type: 'logout', payload: data }), [dispatch]);
    const setLogo = useCallback(data => dispatch({ type: 'setLogo', payload: data }), [dispatch]);

    const handleClick = () => {
        setMsg('Loading...');
        const userLoginAttempt = middlewares.userAuthentication(username, password);
        userLoginAttempt.then(data => {
            return login(data);
        });
    };

    const onKeyPress = event => {
        if (parseInt(event.charCode) === 13 && username !== '' && password !== '') {
            handleClick();
        }
    };

    useEffect(() => {
        const logo = 'RETTSAcademy';
        setLogo(logo);
        if (localStorage.getItem('isRefreshed') === 'true' && localStorage.getItem('accessToken')) {
            middlewares.getUserData()
            .then(data => {
                // data.logo = 'RETTSSim';
                data.path = `/elearning/${data.userRole}/dashboard`;
                data.isUserLoggedIn ? login(data) : logout(data);
            });
        }
    }, [setLogo]);

    return (
        <div>
            <div className="login">
                <Paper>
                    <form>
                        <label htmlFor="username">{'Username'}</label>
                        <TextField
                            type="email"
                            active={true}
                            id="username"
                            // label="Username"
                            lineDirection="center"
                            value={username}
                            onChange={val => setUsername(val)}
                            className="md-cell md-cell--bottom"
                        />
                        <label htmlFor="password">{'Password'}</label>
                        <TextField
                            id="password"
                            // label="Password"
                            lineDirection="center"
                            type="password"
                            value={password}
                            // passwordInitiallyVisible={false}
                            onChange={val => setPassword(val)}
                            onKeyPress={onKeyPress}
                            className="md-cell md-cell--bottom"
                        />
                        <Button
                            id="login_btn"
                            primary
                            raised
                            className="float-right"
                            onClick={handleClick}>
                            Login
                        </Button>
                    </form>
                    <br/>
                    <div className="response_msg">{loginMsg !== '' ? loginMsg : msg}</div>
                </Paper>
            </div>
        </div>
    );
};

export default Login;
