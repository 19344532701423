
import { predicareAuthManager } from '@predicare/retts-lib';
import axios from 'axios';

const userAuthentication = (username, password = null, passwordHash = null) => {
    return predicareAuthManager
    .login(username, password, passwordHash)
    .then(res => {
        if (res && (res.jwt !== null && res.jwt !== undefined)) {
            return (axios.post(`${process.env.REACT_APP_API_BASEURL}/login`, {
                data: res
            })
            .then(response => {
                const role = response.data.userRole === 'admin' ? 'admin' : 'student';
                const userDetails = {
                    isUserLoggedIn: true,
                    msg: response.data.msg,
                    userRole: response.data.userRole,
                    languageId: response.data.languageId,
                    path: `/elearning/${role}/dashboard/`,
                    userDetails: response.data,
                    roleId: response.data.roleId };
                localStorage.setItem('accessToken', response.data.accessToken);
                localStorage.setItem('token', response.data.retts_jwt);
                localStorage.setItem('isRefreshed', 'true');
                localStorage.setItem('view', role);
                localStorage.setItem('roleId', JSON.stringify(response.data.roleId));

                return userDetails;
            })
            .catch(() => {
                return ({ isUserLoggedIn: false, msg: 'Bad request', path: '/' });
            }));
        }

        return ({ isUserLoggedIn: false, msg: 'INCORRECT_CREDENTIALS', path: '/' });
    })
    .catch(() => ({ isUserLoggedIn: false, msg: 'Bad request', path: '/' }));
};

const getUserData = () => {
    return axios.post(`${process.env.REACT_APP_API_BASEURL}/login/getUserData`, { accessToken: localStorage.getItem('accessToken') })
    .then(response => {
        if (response.data.error) {
            return ({ isUserLoggedIn: false, msg: 'Bad request', path: '/' });
        }
        const userDetails = {
            isUserLoggedIn: true,
            msg: response.data.msg,
            userRole: response.data.userRole,
            languageId: response.data.languageId,
            userDetails: response.data,
            roleId: response.data.roleId };

        return userDetails;
    });
};

const userServices = {
    userAuthentication,
    getUserData
};

export { userServices };
