import React from 'react';
import { withTranslation } from 'react-i18next';

/**
 * Component which shows one of two views depending on the state of `showSecondary`
 */
const SwitchableView = props => {
    const { showSecondary, children } = props;

    return (
        (showSecondary && children.length > 1)
            ? children[1] : children[0]
    );
};

export default withTranslation()(SwitchableView);
