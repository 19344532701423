/* eslint-disable consistent-return */
// eslint-disable-next-line max-classes-per-file
import React, { useState, useEffect } from 'react';
import { Grid } from 'react-md';
import { withTranslation } from 'react-i18next';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import _empty from 'lodash/isEmpty';
import _filter from 'lodash/filter';
import { useStore } from '../../../../store';
import { middlewares } from '../../../../middlewares';
import Constants from '../../../../Utils/Constants';
import CourseCard from './CourseCard';

const placeholder = document.createElement('li');
placeholder.className = 'md-cell md-cell--3 placeholder';

const CourseList = props => {
    const {
        state: {
            course: { courseList },
            user: { view }
        }
    } = useStore();

    const [target, setTarget] = useState('');
    const [draggedOver, setDraggedOver] = useState('');
    const [selectedLang, setSelectedLang] = useState();

    const { setCourseList, t } = props;

    const dragStart = e => {
        setTarget(e.currentTarget);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.currentTarget);
    };

    const dragOver = e => {
        e.preventDefault();
        target.style.display = 'none';
        const div = e.target.closest('.md-cell.md-cell--3');
        if (div.className === 'md-cell md-cell--3 placeholder') {
            return null;
        }
        if (div.className === 'md-cell md-cell--3') {
            const id = Number(div.closest('.md-cell.md-cell--3').dataset.id);
            if (id > target.dataset.id) {
                setDraggedOver(id + 1);
                div.parentNode.insertBefore(placeholder, div.nextSibling);
            } else {
                setDraggedOver(id);
                div.parentNode.insertBefore(placeholder, div);
            }
        }
    };

    const dragEnd = e => {
        target.style.display = 'block';
        if (e.target.className === 'md-cell md-cell--3') {
            target.parentNode.removeChild(placeholder);
        }
        const data = _sortBy(courseList, ['sortingIndex']);
        const from = Number(target.dataset.id);
        let to = Number(draggedOver);
        if (from < to) {
            to -= 1;
        }
        if (from !== to) {
            middlewares.setCoursePosition(data[from].id, data[to].id)
            .then(res => {
                setCourseList(res);
            });
        }
    };

    const handleLanguageOnChange = e => {
        setSelectedLang(e);
    };

    const getCoursesForSelectedLanguage = () => {
        return _filter(courseList, { language: selectedLang });
    };

    useEffect(() => {
        const lang = _filter(Constants.getSupportedLanguages(), { value: localStorage.getItem('i18nextLng') });
        setSelectedLang(lang[0].label);
    }, [t]);

    const coursesToDisplay = getCoursesForSelectedLanguage();

    if (_empty(coursesToDisplay)) {
        return (
            <div className="courseContainer">
                <h1>{t('NO_COURSES_AVAILABLE')}</h1>
            </div>
        );
    }

    return (
        <div className="courseContainer">
            <Grid onDragOver={view === 'admin' ? dragOver : (() => {})} className="grid-example">{
                _map(_sortBy(coursesToDisplay, ['sortingIndex']), (course, i) => {
                    const className = (i + 1 === courseList.length) ? 'md-cell md-cell--3' : 'md-cell md-cell--3';

                    return (
                        <li
                            data-id={i}
                            draggable="true"
                            onDragEnd={view === 'admin' ? dragEnd : (() => {})}
                            onDragStart={view === 'admin' ? dragStart : (() => {})}
                            key={i}
                            className={className}
                            size={3}>
                            <CourseCard
                                course={course}
                                key={i}
                                showAdminViews={view === 'admin'}
                                setCourseList={props.setCourseList}
                                getSelectedCourse={props.getSelectedCourse}
                                deleteCourse={props.deleteCourse}
                            />
                        </li>
                    );
                }
            )}</Grid>
        </div>
    );
};

export default withTranslation()(CourseList);
