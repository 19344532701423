/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { FontIcon, Button, Dialog } from 'react-md';
import _map from 'lodash/map';
import AddTagForm from './AddTagForm';
import { middlewares } from '../../../../../../middlewares';
import { useStore } from '../../../../../../store.js';
import './Tags.scss';

const Tags = props => {
    const { t } = props;
    const {
        state: {
            tags: { list }
        },
        dispatch
      } = useStore();

    const [visibleTagForm, setVisibleTagForm] = useState(false);
    const [responseMsgTagForm, setResponseMsgTagForm] = useState('');
    const [newTag, setNewTag] = useState('');

    const hide = () => {
        setResponseMsgTagForm('');
        setVisibleTagForm(!visibleTagForm);
    };

    const alertActions = useCallback(data => dispatch({ type: 'alertPopup', payload: data }), [dispatch]);
    const setTags = useCallback(data => dispatch({ type: 'setTags', payload: data }), [dispatch]);

    const addTags = () => {
        setResponseMsgTagForm(`${t('ADDING')}...`);
        middlewares.addNewTag(newTag)
        .then(res => {
            setTags(res.taglist);
            setResponseMsgTagForm(t(res.msg));
        });
        setTimeout(hide, 1000);
    };

    const handleNewTag = e => {
        setNewTag(e.toLowerCase());
    };

    const hideAlert = () => {
        alertActions({ displayAlert: false, alertActions: {} });
    };

    const deleteTag = id => {
        const deleteTagAttempt = middlewares.deleteTag(id);
        deleteTagAttempt.then(res => {
            setTags(res.taglist);
            hideAlert();
        });
    };

    const showDeleteAlert = (e, id) => {
        const actions = {
            showConfirmButton: true,
            msg: t('DELETE_CONFIRM_MSG'),
            okBtn: deleteTag,
            cancelBtn: hideAlert,
            callBackProps: id,
        };
        alertActions({ displayAlert: true, alertActions: actions });
    };

    const actions = [{
        id: 'cancel',
        secondary: true,
        children: t('CANCEL'),
        onClick: hide,
      }, {
        id: 'add',
        primary: true,
        children: t('ADD'),
        onClick: addTags,
    }];

    return (
        <div className="tagContainer">
            <div className="tagLists">
                <div className="tagListHeader">
                    <h2>{t('AVAILABLE_TAGS')}</h2>
                    <Button flat primary iconChildren="add" onClick={hide}>{t('ADD')}</Button>
                </div>
                <div id="tags-dialog" aria-labelledby="tags-dialog-title" className="tagDialogBox">
                    {
                        _map(list, item => (
                            <div className="tags" key={item._id}>
                                {item.tagname}
                                <FontIcon onClick={e => showDeleteAlert(e, item._id)}>delete_sweep</FontIcon>
                            </div>
                        ))
                    }
                </div>
            </div>
            {visibleTagForm && (
                <div className="DialogContainer">
                    <Dialog
                        id="a12"
                        title="Add New Tag"
                        actions={actions}
                        contentClassName="md-grid"
                        >
                        <AddTagForm
                            handleNewTag={handleNewTag}
                            responseMsg={responseMsgTagForm}/>
                    </Dialog>
                </div>
            )}
        </div>
    );
};

export default withTranslation()(Tags);
