
import React, { useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import _ from 'lodash';
import PrivateRoute from './private-route';
import Login from './Login';
import Dashboard from './Dashboard';
import ELearningDashboard from './ELearningDashboard';
import ActiveAdmin from './Dashboard/admin/AdminList';
import { useStore } from '../../store.js';
import Alert from '../common/Alert';
import RettsAcademy from './RettsAcademy';
import { middlewares } from '../../middlewares';
import Diploma from './Diploma';
import Parent from './Diploma/Parent';


const Layout = () => {
    const {
        state: {
            user: { path, loggedIn },
            alert: { displayAlert, alertActions }
        },
        dispatch
      } = useStore();

    const setPath = useCallback(data => dispatch({ type: 'setPath', payload: data }), [dispatch]);
    const login = useCallback(data => dispatch({ type: 'login', payload: data }), [dispatch]);

    useEffect(() => {
        if (!loggedIn) {
            const url = window.location.href;
            if (url.includes('?')) {
                const queryParams = url.split('?')[1];
                const params = queryParams.split('&');
                let userName = '';
                let passwordHash = '';
                params.forEach(param => {
                    const pair = param.split('=');
                    if (pair[0] === 'username') {
                        userName = pair[1].trim();
                    }
                    if (pair[0] === 'passwordhash') {
                        passwordHash = pair[1].trim();
                    }
                });

                if (!_.isEmpty(userName) && !_.isEmpty(passwordHash)) {
                    const userLoginAttempt = middlewares.userAuthentication(userName, null, passwordHash);
                    userLoginAttempt.then(data => {
                        return login(data);
                    });
                }
            }
        }
    }, [loggedIn, login, setPath]);

    return (
        <div>
            {displayAlert ? <Alert actions={alertActions}/> : null}
            <Router>
                <div>
                    { path && <Redirect push to={path} /> }
                    <Route exact path="/" component={Login} />
                    <PrivateRoute exact path="/admin" component={ActiveAdmin} />
                    <PrivateRoute exact path="/rettsacademy" component={RettsAcademy} />
                    <PrivateRoute exact path="/rettssim/admin/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/rettssim/student/dashboard" component={Dashboard} />
                    <PrivateRoute exact path="/elearning/admin/dashboard" component={ELearningDashboard} />
                    <PrivateRoute exact path="/elearning/student/dashboard" component={ELearningDashboard} />
                    <Route exact path="/headless/diploma" component={Diploma} />
                    {process.env.NODE_ENV === 'development' ? <Route path="/courses/retts grundkurs_1589978020951" component={Parent} /> : null}
                </div>
            </Router>
        </div>
    );
};

export default Layout;
