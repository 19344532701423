import React from 'react';
import { withTranslation } from 'react-i18next';
import { StoreProvider } from './store';
import Header from './components/Header/index';
import Layout from './components/Layout/index';
import Footer from './components/Footer/index';
import './index.scss';

const App = () => {
    const location = new URL(document.location.href);

    return (
        <StoreProvider>
            {
                location.pathname.includes('/headless') ? null : <Header/>
            }
            <Layout/>
            {
                location.pathname.includes('/headless') ? null : <Footer/>
            }
        </StoreProvider>
    );
};
export default withTranslation()(App);
