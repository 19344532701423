/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    TextField
  } from 'react-md';

const AddAdminForm = props => {
    const { t } = props;

    return (
        <div className="md-grid">
            <label htmlFor="useremail">{t('USEREMAIL')}</label>
            <TextField
                id="useremail"
                // label={t('USEREMAIL')}
                type="email"
                placeholder="abc@retts.com"
                onChange={props.handleUserName}
                className="md-cell md-cell--12" />
            <div className="response_msg">
                {props.responseMsg}
            </div>
        </div>
    );
};

export default withTranslation()(AddAdminForm);
