import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Button,
    Card,
    CardTitle, Divider,
    List,
} from 'react-md';
import ResourceItem from './ResourceItem';
import SwitchableView from './SwitchableView';
import GroupEditControls from './GroupEditControls';
import { getLanguageCode } from './utils';

/**
 * A single card with a group (listing) of resource items
 */
const GroupCard = props => {
    const { group, index, isEditable } = props;

    const [isEditing, setEditing] = useState(false);

    const lang = getLanguageCode();

    const toggleEditMode = () => {
        setEditing(!isEditing);
    };

    const saveChanges = newGroupValues => {
        console.log(`Saving new values: ${newGroupValues.title}, ${newGroupValues.description}`);
    };

    const getTitle = () => {
        return group.title[lang] || group.name;
    };

    const getDescription = () => {
        return group.description[lang] || '';
    };

    return (
        <Card key={index}>
            <SwitchableView showSecondary={isEditing}>
                <CardTitle title={getTitle()} subtitle={getDescription()}>
                    {isEditable ? (
                        <Button
                            className="md-cell--right"
                            icon
                            onClick={toggleEditMode}
                    >mode_edit</Button>
                    ) : null}
                </CardTitle>
                <GroupEditControls group={group} onSave={saveChanges} onCancel={toggleEditMode} lang={lang}/>
            </SwitchableView>
            <List className="md-paper md-paper--1">
                {group.resources.map(resource => (
                    <ResourceItem groupName={group.name} resource={resource} key={resource.id}/>
                ))}
            </List>
        </Card>
    );
};

export default withTranslation()(GroupCard);
