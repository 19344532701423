import React from 'react';
import { Avatar, FontIcon } from 'react-md';
import 'font-awesome/css/font-awesome.css';

const FileIconAvatar = props => {
    const {
        extension,
        // TODO: Dependency for 'awesome' icons does not work. Only 'material' will show icons.
        firstChoice = 'material'
    } = props;

    let materialIconName = null;
    let awesomeIconName = null;
    const awesomeStyle = 'far';

    let cleanedExtension = extension;

    if (extension.startsWith('.')) {
        cleanedExtension = extension.substr(1);
    }

    switch (cleanedExtension.toLowerCase()) {
        case 'jpg':
        case 'jpeg':
            materialIconName = 'insert-photo';
            awesomeIconName = `${awesomeStyle} fa-image`;
            break;
        case 'pdf':
            materialIconName = 'description';
            awesomeIconName = `${awesomeStyle} file-pdf`;
            break;
        case 'link':
            materialIconName = 'language';
            break;
        case 'missing':
            materialIconName = 'file_download_off';
            break;
        default:
            materialIconName = 'insert_drive_file';
            break;
    }

    const getFontIcon = () => {
        const fontIcon = (firstChoice === 'material')
            ? (<FontIcon>{materialIconName}</FontIcon>)
            : (<FontIcon iconClassName={awesomeIconName}/>);

        return (fontIcon !== null)
            ? fontIcon
            : (<FontIcon iconClassName={awesomeIconName}/>);
    };

    return (
        <Avatar icon={getFontIcon()}/>
    );
};

export default FileIconAvatar;
