/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Autocomplete, Switch, SelectionControlGroup, FontIcon } from 'react-md';

import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _includes from 'lodash/includes';
import _sortBy from 'lodash/sortBy';
import _concat from 'lodash/concat';
import _remove from 'lodash/remove';
import { middlewares } from '../../../../../../middlewares';

const EssData = props => {
    const {
        isVisibleError,
        alertMsg,
        renderLeftBtn,
        renderRightBtn,
        essResult,
        essPackageId,
        pSymptoms,
        handleStudentSelectedValue,
        studentResult,
        selectedEssId,
        selectedEssSymptomsList,
        handleErrorMsg,
        t } = props;

    const [essList, setEssList] = useState([]);
    const [selectedEssData, setSelectedEssData] = useState(studentResult.selectedEssData);

    const handleEssSearchPhase = searchPhase => {
        const fetchLang = async () => {
            const ess = await middlewares.searchEssPackages(essPackageId, searchPhase);
            setEssList(ess);
        };

        fetchLang();
    };

    const handleSelectedEss = searchPhrase => {
        const fetchLang = async () => {
            const data = await middlewares.getEssDataById(_filter(essList, { searchPhrase })[0].id);
            setSelectedEssData(data);
            selectedEssId !== data.id && handleErrorMsg(t('INCORRECT_ESS'));
            handleStudentSelectedValue({ selectedEssData: data, selectedEssSymptomsList: [] });
        };
        fetchLang();
    };

    const handleEssSymptomsSwitch = (e, symptoms) => {
        if (e) {
           const values = _concat(studentResult.selectedEssSymptomsList, symptoms);
           handleStudentSelectedValue({ selectedEssSymptomsList: values });
        } else {
            const values = _remove(studentResult.selectedEssSymptomsList, item => item !== symptoms);
            handleStudentSelectedValue({ selectedEssSymptomsList: values });
        }
    };

    const handleStudentSelectedEssPrioValue = val => {
        handleStudentSelectedValue({ selectedEssPrioValue: val });
    };

    const getDoneOrCloseOption = (selectedEssSymptomsList, symptoms) => {
        const done = <FontIcon className="correct">done</FontIcon>;
        const close = <FontIcon className="wrong">close</FontIcon>;
        if (_includes(selectedEssSymptomsList, symptoms.id)) {
            return done;
        }

        return close;
    };

    const getDoneOrCloseIcon = (studentResult, symptoms, selectedEssSymptomsList) => {
        let iconChoice = null;
        if (_includes(studentResult.selectedEssSymptomsList, symptoms.id)) {
            iconChoice = getDoneOrCloseOption(selectedEssSymptomsList, symptoms);
        }

        return iconChoice;
    };

    return (
        <div>
            <div className="wrapper_container">
                <div className="wrapper essContent">
                    <div className="essWrapperContainer">
                        <div className="essTitle">
                            {t('SEARCH_ESS_AND_SELECT')}
                        </div>
                        <div className="innerContainer">
                            <label htmlFor="SearchBar">{t('SEARCH_ESS')}</label>
                            <Autocomplete
                                id="SearchBar"
                                // label={t('SEARCH_ESS')}
                                className="md-cell md-cell--4 essSearch"
                                onAutocomplete={e => handleSelectedEss(e)}
                                clearOnAutocomplete={true}
                                onChange={e => handleEssSearchPhase(e)}
                                data={_map(essList, ess => ess.searchPhrase)}
                            />
                            {selectedEssData && selectedEssData.id && (
                            <div>
                                <div className="essHeading">
                                    {selectedEssData.number}
.
                                    {selectedEssData.packageType}
                                </div>
                                <div className="divider"/>
                                <ul>
                                    {_map(selectedEssData.searchPhrases, searchPhrase => (
                                        <li key={searchPhrase.id}>{searchPhrase.searchPhrase}</li>
                                ))}
                                </ul>
                                <div className="divider"/>
                                <ul>
                                    {_map(selectedEssData.links, links => (
                                        <li key={links.id}>{links.description}</li>
                                ))}
                                </ul>
                                {
                                    selectedEssData.preActions && selectedEssData.preActions.length ? (
                                        <div>
                                            <div className="divider"/>
                                            <div className="essHeading">
                                                {t('PREVENTIVE_ACTION')}
                                            </div>
                                            <ul>
                                                {
                                                    _map(selectedEssData.preActions, preActions => (
                                                        <li key={preActions.id}>{preActions.description}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    ) : ''
                                }
                                <div className="divider"/>
                                {
                                    _map(_sortBy(selectedEssData.symptoms, ['essRisklevel']), symptoms => (
                                        <div className="essSymptoms" key={symptoms.id}>
                                            <Switch
                                                className={`risklevel_${symptoms.essRisklevel}`}
                                                id={symptoms.id}
                                                type="switch"
                                                checked={_includes(studentResult.selectedEssSymptomsList, symptoms.id)}
                                                onChange={e => handleEssSymptomsSwitch(e, symptoms.id)}
                                                label={symptoms.description}
                                                name={symptoms.description}
                                            />
                                            {
                                                getDoneOrCloseIcon(studentResult, symptoms, selectedEssSymptomsList)
                                            }
                                        </div>
                                    ))
                                }
                                <div className="divider"/>
                                <div className="essHeading">
                                    <span>{t(`${selectedEssData.packageType}`)}</span>
                                    <span>&nbsp;</span>
                                    <span>{t('FACT_BOX')}</span>
                                </div>
                                <div className="infos">
                                    {_map(selectedEssData.infos, infos => (
                                        <div className="essContent" key={infos.id}>{infos.text}</div>
                                ))}
                                </div>
                            </div>
                    )}
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="innerContainer">
                        <div className="symptoms">
                            <div className="essHeading">{t('SYMPTOMS')}</div>
                            {pSymptoms}
                        </div>
                    </div>
                    <div className="stepsFooter">
                        {renderLeftBtn}
                        <div className="resultBoxContainer">
                            <div className="textAboveTickBox">
                                {t('CHOOSE_ESS_PRIO')}
                            </div>
                            <div className="result_box">
                                <SelectionControlGroup
                                    id={essResult.key}
                                    type="radio"
                                    value={studentResult.selectedEssPrioValue}
                                    onChange={handleStudentSelectedEssPrioValue}
                                    checkedRadioIcon={<FontIcon>check_box</FontIcon>}
                                    uncheckedRadioIcon={<FontIcon>check_box_outline_blank</FontIcon>}
                                    controls={essResult.controls}
                                />
                            </div>
                        </div>
                        {renderRightBtn}
                    </div>

                    {isVisibleError
                    && (
                    <div className="errorBox">
                        <FontIcon>error</FontIcon>
                        <span>{alertMsg}</span>
                        <span onClick={() => props.closeErrorMsg()}>X</span>
                    </div>
)}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(EssData);
