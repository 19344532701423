/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Button,
    TextField,
    SelectionControlGroup } from 'react-md';
import { useStore } from '../../../../../../store.js';
// import { middlewares } from "../../../../../../middlewares";


const QuizForm = props => {
    const { t } = props;
    const {
        state: {
          quiz: { newQuiz }
        },
        dispatch
    } = useStore();

    const handleNextBtn = page => {
        // if(newQuiz.type==='random'){
            props.handleSection(page);
        // }else{
        //     middlewares.getQuestionsByFilter({filters:newQuiz.filters, type:newQuiz.type, count: newQuiz.questionCount})
        //     .then(data => {
        //         setFilteredQuestion(data);
        //         props.handleSection(page);
        //     });
        // }
    };

    const setNewQuizValues = useCallback((data, key) => dispatch({ type: 'setNewQuizValues', payload: { data, key } }), [dispatch]);
    // const setFilteredQuestion = useCallback(data => dispatch({ type: "setFilteredQuestion", payload: data}), [dispatch]);

    return (
        <div className="container">
            <div>
                <div className="categoryHeading">{t('CREATE_QUIZ')}</div>
                <label htmlFor="title">{t('TITLE')}</label>
                <TextField
                    id="title"
                    // label={t('TITLE')}
                    type="text"
                    required
                    value={newQuiz.title}
                    onChange={e => setNewQuizValues(e, 'title')}
                    className="md-cell md-cell--12" />
                <label htmlFor="description">{t('DESCRIPTION')}</label>
                <TextField
                    id="description"
                    // label={t('DESCRIPTION')}
                    type="text"
                    rows={1}
                    required
                    value={newQuiz.description}
                    onChange={e => setNewQuizValues(e, 'description')}
                    className="md-cell md-cell--12" />

                <SelectionControlGroup
                    id="selection-control-group-radios"
                    name="radio-example"
                    type="radio"
                    label={t('QUESTIONS_SELECTION_TYPE')}
                    onChange={e => setNewQuizValues(e, 'type')}
                    className="md-cell md-cell--12"
                    value={newQuiz.type}
                    controls={[
                        {
                            label: t('MANUAL_SELECTION'),
                            value: 'manual',
                        }, {
                            label: t('RANDOM_SELECTION'),
                            value: 'random',
                        }
                    ]}
                />
                {
                    newQuiz.type === 'random'
                    && (
                    <div>
                        <label htmlFor="number_of_questions">{t('NUMBER_OF_QUESTIONS')}</label>
                        <TextField
                            id="number_of_questions"
                            // label={t('NUMBER_OF_QUESTIONS')}
                            type="number"
                            required
                            min={1}
                            pattern="[0-9]"
                            value={newQuiz.questionCount}
                            onChange={e => setNewQuizValues(e, 'questionCount')}
                            className="md-cell md-cell--12"
                        />
                    </div>
)
                }
            </div>
            <div className="footer">
                <Button
                    flat
                    primary
                    disabled={!newQuiz.title || !((newQuiz.type === 'random' && newQuiz.questionCount) || newQuiz.type === 'manual') || !newQuiz.description}
                    iconBefore={false}
                    iconChildren="navigate_next"
                    // onClick={newQuiz.type === 'manual' ? ()=>handleNextBtn(3): ()=>handleNextBtn(2)}>Next</Button>
                    onClick={() => handleNextBtn(2)}>
Next
                </Button>
            </div>
        </div>
    );
};


export default withTranslation()(QuizForm);
