/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import {
    DataTable,
    TableHeader,
    TableBody,
    TableRow,
    TableColumn,
    TablePagination,
    Button
  } from 'react-md';
import _map from 'lodash/map';
import { useStore } from '../../../../../../store.js';
import { middlewares } from '../../../../../../middlewares';
import AddQuestions from '../AddQuestions';
import './ManageQuestions.scss';

const ManageQuestions = props => {
    const { t } = props;
    const defaultRowsPerPage = 25;

    const {
        state: {
            user: { userDetails },
            questions: { newQuestion, quesList }
        },
        dispatch
      } = useStore();

    const [rows, setRows] = useState(quesList.length);
    const [sno, setSno] = useState(0);
    const [slicedData, setSlicedData] = useState(quesList.slice(0, defaultRowsPerPage));
    const [isOpenEdit, setOpenEdit] = useState(false);

    const tableHeader = () => {
        return (
            <TableRow selectable={false}>
                <TableColumn>S.No</TableColumn>
                <TableColumn>{t('TITLE')}</TableColumn>
                <TableColumn>{t('TAGS')}</TableColumn>
                <TableColumn>{t('SITUATIONS')}</TableColumn>
                <TableColumn>{t('CREATED_BY')}</TableColumn>
                <TableColumn>{t('LAST_UPDATED_BY')}</TableColumn>
                <TableColumn>{t('EDIT')}</TableColumn>
                <TableColumn>{t('DELETE')}</TableColumn>
            </TableRow>
        );
    };

    const handlePagination = (start, rowsPerPage) => {
        setSno(start / rowsPerPage);
        setSlicedData(quesList.slice(start, start + rowsPerPage));
    };

    const alertActions = useCallback(data => dispatch({ type: 'alertPopup', payload: data }), [dispatch]);
    const setQuestions = useCallback(data => dispatch({ type: 'setQuestions', payload: data }), [dispatch]);
    const resetQuestionForm = useCallback(() => dispatch({ type: 'resetQuestion', payload: {} }), [dispatch]);
    const setEditedQuestionData = useCallback(data => dispatch({ type: 'setEditedQuestionData', payload: data }), [dispatch]);

    const hideAlert = () => {
        alertActions({ displayAlert: false, alertActions: {} });
    };

    const deleteQuestion = id => {
        const deleteQuestionAttempt = middlewares.deleteQuestion({ id, name: userDetails.username, type: 'delete' });
        deleteQuestionAttempt.then(res => {
            setRows(res.data.length);
            setQuestions(res.data);
            setSlicedData(res.data.slice(sno * defaultRowsPerPage, (sno * defaultRowsPerPage) + defaultRowsPerPage));
            hideAlert();
        });
    };


    const showDeleteAlert = id => {
        const actions = {
            showConfirmButton: true,
            msg: 'Are you sure to delete this?',
            okBtn: deleteQuestion,
            cancelBtn: hideAlert,
            callBackProps: id,
        };
        alertActions({ displayAlert: true, alertActions: actions });
    };

    const showEditForm = question => {
        setOpenEdit(true);
        setEditedQuestionData({ ...question, isEdited: true });
    };

    const closeEditPopup = () => {
        resetQuestionForm();
        setOpenEdit(false);
    };

    const updateQuestion = () => {
        const updateData = middlewares.updateQuestion({ ...newQuestion, lastUpdatedBy: userDetails.username });
        updateData.then(res => {
            const actions = {
                msg: res.msg,
                isShowBtns: false,
            };
            alertActions({ displayAlert: true, alertActions: actions });
            if (res.validation) {
                resetQuestionForm();
                setRows(res.data.length);
                setQuestions(res.data);
                setSlicedData(res.data.slice(sno * defaultRowsPerPage, (sno * defaultRowsPerPage) + defaultRowsPerPage));
                setOpenEdit(false);
            }
            setTimeout(() => {
                alertActions({ displayAlert: false, alertActions: {} });
            }, 1000);
        });
    };

    return (
        <div className="ManageQuestionsContainer">
            {!isOpenEdit && (
            <div className="innerContainer">
                <DataTable plain>
                    <TableHeader>
                        {tableHeader()}
                    </TableHeader>
                    <TableBody>
                        {_map(slicedData, (item, i) => (
                            <TableRow key={i} selectable={false}>
                                <TableColumn>{i + (sno * defaultRowsPerPage) + 1}</TableColumn>
                                <TableColumn>{item.title}</TableColumn>
                                <TableColumn>
                                    {
                                        _map(item.tags, (tag, i) => <div key={i} className="tagList">{tag}</div>)
                                    }
                                </TableColumn>
                                <TableColumn >
                                    <div className="pSituationText">{item.pSituation}</div>
                                </TableColumn>
                                <TableColumn>{item.createdBy}</TableColumn>
                                <TableColumn>{item.lastUpdatedBy}</TableColumn>
                                <TableColumn>
                                    <Button icon primary onClick={() => showEditForm(item)} iconChildren="edit" />
                                </TableColumn>
                                <TableColumn>
                                    <Button icon secondary onClick={() => showDeleteAlert(item._id)} iconChildren="delete_sweep" />
                                </TableColumn>
                            </TableRow>
                        ))}
                    </TableBody>
                </DataTable>

                <div className="footer">
                    <DataTable baseId="simple-pagination">
                        <TablePagination
                            rows={rows}
                            defaultPage={sno + 1}
                            defaultRowsPerPage={defaultRowsPerPage}
                            onPagination={handlePagination} />
                    </DataTable>
                </div>
            </div>
)}

            {isOpenEdit
                && (
                <div className="editPanel">
                    <AddQuestions close={closeEditPopup} update={updateQuestion}/>
                </div>
)}
        </div>
    );
};

export default withTranslation()(ManageQuestions);
