import _uniq from 'lodash/uniq';
import _remove from 'lodash/remove';
import _includes from 'lodash/includes';

export const quizInitialState = {
    quiz: {
        newQuiz: {
            title: '',
            questionCount: '',
            type: 'manual',
            description: '',
            filters: {},
            selectedQuestions: [],
            selectedProduct: '',
            selectedVersion: '',
            selectedLang: '',
            productVersionList: [],
            productLangList: [],
        },
        quizList: [],
    }
};

export const quizActions = {

    setNewQuizValues: (state, payload) => {
        const val = { ...state.quiz.newQuiz, [payload.key]: payload.data };

        return { quiz: { ...state.quiz, newQuiz: val } };
    },

    addNewFilter: (state, payload) => {
      const Filters = state.quiz.newQuiz.filters;
      const temp = Filters[payload.data.key] ? Filters[payload.data.key] : [];
      temp.push(payload.data.value);

      Filters[payload.data.key] = _uniq(temp);

      const val = { ...state.quiz.newQuiz, filters: Filters };

      return { quiz: { ...state.quiz, newQuiz: val } };
    },

    removeFilter: (state, payload) => {
      const Filters = state.quiz.newQuiz.filters;
      const temp = _remove(Filters[payload.key], item => item !== payload.value);
      let newFilter;
      if (temp.length) {
        newFilter = { ...Filters, [payload.key]: temp };
      } else {
        newFilter = delete Filters[payload.key] && Filters;
      }
      const val = { ...state.quiz.newQuiz, filters: newFilter };

return { quiz: { ...state.quiz, newQuiz: val } };
    },

    setSelectedQuestions: (state, payload) => {
        const selectedQuestions = state.quiz.newQuiz.selectedQuestions;

        if (_includes(selectedQuestions, payload)) {
          _remove(selectedQuestions, item => item === payload);
        } else {
         ((state.quiz.newQuiz.questionCount > selectedQuestions.length && state.quiz.newQuiz.type === 'random') || state.quiz.newQuiz.type === 'manual') && selectedQuestions.push(payload);
        }

      const val = { ...state.quiz.newQuiz, selectedQuestions };

return { quiz: { ...state.quiz, newQuiz: val } };
    },

    setBulkSelectedQuesion: (state, payload) => {
        const val = { ...state.quiz.newQuiz, selectedQuestions: payload };

        return { quiz: { ...state.quiz, newQuiz: val } };
    },

    clearSelectedQuesion: state => {
      const val = { ...state.quiz.newQuiz, selectedQuestions: [] };

      return { quiz: { ...state.quiz, newQuiz: val } };
  },

    resetQuiz: state => {
      return { quiz: { ...state.quiz,
        newQuiz: {
          title: '',
          questionCount: '',
          type: 'manual',
          description: '',
          filters: {},
          selectedQuestions: [],
          selectedProduct: '',
          selectedVersion: '',
          selectedLang: '',
        }
      }
    };
    },

    setQuizList: (state, payload) => {
      return { quiz: { ...state.quiz, quizList: payload } };
    }
};
