/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import QuestionLayout from '../QuestionLayout';

const QuizLayout = props => {
    const { t } = props;
    const { handleStartQuiz, questionList, onComplete } = props;

    const [caseId, setCurrentcaseId] = useState(questionList.savedQuestions);

    const handleCurrentCaseId = index => {
        if ((caseId + index) >= questionList.selectedQuestions.length) {
            // eslint-disable-next-line no-alert
            alert(t('QUIZ_COMPLETED'));
            onComplete();
        } else if ((caseId + index) < 0) {
            handleStartQuiz(false);
        } else {
            setCurrentcaseId(caseId + index);
        }
    };

    const handleContinueLater = savedQuestionNo => {
        props.handleContinueLater(savedQuestionNo);
    };

    return (
        <QuestionLayout
            questionId={questionList.selectedQuestions[caseId]}
            currentCase={caseId}
            handleContinueLater={handleContinueLater}
            handleCaseId={handleCurrentCaseId}
            totalCases={questionList.selectedQuestions.length}/>
    );
};

export default withTranslation()(QuizLayout);
