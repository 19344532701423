import React from 'react';
import { withTranslation } from 'react-i18next';
import { useStore } from '../../store';
import './header.scss';
import Menu from './Menu.jsx';

const rettselearning = require('@internal/assets/rettsElearning_logo-white.png');


const Header = () => {
    const {
        state: {
            user: {
                logo
            }
        }
    } = useStore();

    const renderLogo = () => {
        let logoImage;
        switch (logo) {
            case 'RETTSSim':
                logoImage = rettselearning; // rettssim;
                break;
            case 'RETTSELearning':
                logoImage = rettselearning;
                break;
            default:
                logoImage = rettselearning; // rettsacademy;
        }

        return <img alt="logo" src={logoImage}/>;
    };


    return (
        <div className="headerContainer">
            <div className="logo">
                <a href={'/'}>
                    {renderLogo()}
                </a>
            </div>
            <div className="menuBar">
                <Menu/>
            </div>
        </div>
    );
};

export default withTranslation()(Header);
