
const getSupportedLanguages = () => [
    { label: 'Svenska', value: 'sv', languageId: 1 },
    { label: 'Norsk', value: 'no', languageId: 2 },
    { label: 'English', value: 'en-US', languageId: 5 },
    { label: 'Deutsch', value: 'de', languageId: 6 },
];

const getLanguageMapping = () => {
    const languageMapping = {};
    getSupportedLanguages().forEach(lang => {
       languageMapping[lang.value] = lang.label;
    });
    languageMapping.en = languageMapping['en-US'];

    return languageMapping;
};

const getQuizLangList = () => [
    { label: 'Svenska', value: 'Svenska' },
    { label: 'Norsk', value: 'Norsk' },
    { label: 'English', value: 'English' },
    { label: 'Deutsch', value: 'Deutsch' },
];

const Constants = {
    getSupportedLanguages,
    getQuizLangList,
    getLanguageMapping
};

export default Constants;
