/* eslint-disable func-names */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Dialog } from 'react-md';
import './Alert.scss';

const Alert = props => {
    const handleOkBtn = () => {
        const args = props.actions.callBackProps ? props.actions.callBackProps : null;
        props.actions.okBtn(args);
    };

    const handleCancelBtn = () => {
        props.actions.cancelBtn();
    };

    const confirmActions = function (inputId) {
        return [{
        id: `${inputId}-dialog-cancel`,
        className: 'alert-cancel',
        secondary: true,
        children: 'No',
        onClick: handleCancelBtn
      }, {
        id: `${inputId}-dialog-ok`,
        className: 'alert-ok',
        primary: true,
        children: 'Yes',
        onClick: handleOkBtn
      }];
    };

    const actions = function(inputId) {
        return [{
        id: `${inputId}-dialog-ok`,
        className: 'alert-ok',
        secondary: true,
        children: 'ok',
        onClick: handleOkBtn
      }];
    };

    const { isShowBtns = true, id = 'e' } = props.actions;

    const getActions = id => {
        if (isShowBtns) {
            if (props.actions.showConfirmButton) {
                return confirmActions(id);
            }

            return actions(id);
        }

        return {};
    };

return (
    <div className="alertBox">
        <Dialog
            id="static-dialog"
            actions={getActions(id)}
            containFocus={false}
            aria-labelledby="static-dialog-title"
            className="md-background--card"
                >
            {props.actions.msg}
        </Dialog>
    </div>
    );
};

export default Alert;
