/* eslint-disable react/prop-types */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { SelectionControlGroup, FontIcon } from 'react-md';

const FinalPrio = props => {
    const {
        isVisibleError,
        alertMsg,
        renderLeftBtn,
        renderRightBtn,
        studentResult,
        essResult,
        vpResult,
        finalResult,
        handleStudentSelectedValue,
        t } = props;

    const handleStudentSelectedFinalPrioValue = val => {
        handleStudentSelectedValue({ selectedFinalPrioValue: val });
    };

    return (
        <div className="finalPri_outerContainer">
            <div className="finalPrio_container">
                <div className="finalPrio_wrapper">
                    <p>{t('VP')}</p>
                    <div className="result_box">
                        <SelectionControlGroup
                            id={essResult.key}
                            type="radio"
                            value={studentResult.selectedEssPrioValue}
                            checkedRadioIcon={<FontIcon>check_box</FontIcon>}
                            uncheckedRadioIcon={<FontIcon>check_box_outline_blank</FontIcon>}
                            controls={essResult.controls}
                            onChange={() => {}}
                />
                    </div>
                </div>
                <div className="finalPrio_wrapper">
                    <p>{`+ ${t('ESS')}`}</p>
                    <div className="result_box">
                        <SelectionControlGroup
                            id={vpResult.key}
                            type="radio"
                            value={studentResult.selectedVpPrioValue}
                            checkedRadioIcon={<FontIcon>check_box</FontIcon>}
                            uncheckedRadioIcon={<FontIcon>check_box_outline_blank</FontIcon>}
                            controls={vpResult.controls}
                            onChange={() => {}}
                />
                    </div>
                </div>

                <div className="finalPrio_wrapper">
                    <p>
=
                        {' '}
                        {t('RETTS')}
                    </p>
                </div>
            </div>
            <div className="finalPrio_wrapper">
                <div className="stepsFooter">
                    {renderLeftBtn}
                    <div className="resultBoxContainer">
                        <div className="textAboveTickBox">
                            {t('CHOOSE_OVERALL_PRIO')}
                        </div>
                        <div className="result_box">
                            <SelectionControlGroup
                                id={finalResult.key}
                                type="radio"
                                value={studentResult.selectedFinalPrioValue}
                                onChange={handleStudentSelectedFinalPrioValue}
                                checkedRadioIcon={<FontIcon>check_box</FontIcon>}
                                uncheckedRadioIcon={<FontIcon>check_box_outline_blank</FontIcon>}
                                controls={finalResult.controls}/>
                        </div>
                    </div>
                    {renderRightBtn}
                </div>
                {isVisibleError
                && (
                <div className="errorBox">
                    <FontIcon>error</FontIcon>
                    <span>{alertMsg}</span>
                    <span onClick={() => props.closeErrorMsg()}>X</span>
                </div>
)}
            </div>

        </div>
    );
};

export default withTranslation()(FinalPrio);
