/* eslint-disable react/prop-types */
import React from 'react';
import _filter from 'lodash/filter';
import _map from 'lodash/map';
import VpACategory from './VpACategory';
import VpBCategory from './VpBCategory';
import VpCCategory from './VpCCategory';
import VpDCategory from './VpDCategory';
import VpECategory from './VpECategory';
import './Triage.scss';

const VpData = props => {
    const { vpData } = props;

    const renderCheckBox = items => {
        return _map(items, (item, i) => {
          let text = '';
          if (item.description === '-') {
            return null;
          }
          if (item.measurelimit !== null) {
            text = `${item.measurelimit.operator} ${item.measurelimit.value} ${item.measurelimit.unit !== null ? item.measurelimit.unit : ''}`;
          }
          if (item.interval !== null) {
            text = `${item.interval.low} ${item.interval.unit !== null ? item.interval.unit : ''} - ${item.interval.high} ${item.interval.unit !== null ? item.interval.unit : ''} `;
          }

          return (
              <span key={i}>
                  {`${item.description} ${text}`}
              </span>
            );
        });
    };

    return (vpData.length ? (
        <div className="section-body">
            <VpACategory renderCheckBox={renderCheckBox} vpData={_filter(vpData, { vpCategory: 'A' })}/>
            <VpBCategory renderCheckBox={renderCheckBox} vpData={_filter(vpData, { vpCategory: 'B' })}/>
            <VpCCategory renderCheckBox={renderCheckBox} vpData={_filter(vpData, { vpCategory: 'C' })}/>
            <VpDCategory renderCheckBox={renderCheckBox} vpData={_filter(vpData, { vpCategory: 'D' })}/>
            <VpECategory renderCheckBox={renderCheckBox} vpData={_filter(vpData, { vpCategory: 'E' })}/>
        </div>
) : null);
};

export default VpData;
