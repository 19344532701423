/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from 'react';
import {
    Button,
    DataTable,
    Dialog,
    TableHeader,
    TableBody,
    TableRow,
    TableColumn
  } from 'react-md';
import { withTranslation } from 'react-i18next';
import _map from 'lodash/map';
import { useStore } from '../../../../../store.js';
import { middlewares } from '../../../../../middlewares';
import AddAdminForm from './AddAdminForm';
import './ActiveAdmin.scss';

const ActiveAdmin = props => {
    const { t } = props;
    const {
        state: {
          user: { userDetails }
        },
        dispatch
      } = useStore();

    const [list, setList] = useState([]);
    const [visible, setVisible] = useState(false);
    const [newAdmin, setNewAdmin] = useState('');
    const [responseMsg, setResponseMsg] = useState('');

    const setPath = useCallback(data => dispatch({ type: 'setPath', payload: data }), [dispatch]);

    useEffect(() => {
        const fetchData = async () => {
            const adminList = await middlewares.getAllAdminList()
            .then(list => list);

            setList(adminList);
        };
        fetchData();
    }, []);

    const hide = () => {
        setResponseMsg('');
        setVisible(!visible);
    };

    const addAdmin = () => {
        setResponseMsg(`${t('ADDING')}...`);
        middlewares.addNewAdmin(newAdmin, userDetails.username)
        .then(res => {
            setList(res.user);
            if (res.error && res.error.code === 11000) {
                setResponseMsg(t('USEREMAIL_DUPLICATED'));
            } else {
                setResponseMsg(t(res.msg));
            }
        });
    };

    const goToRettsSim = () => {
        setPath('/rettssim/student/dashboard');
    };

    const addOneAdmin = () => {
        addAdmin();
        setTimeout(hide, 1000);
    };

    const addManyAdmin = () => {
        addAdmin();
    };

    const actions = [{
        id: 'add-admin-alert-cancel',
        secondary: true,
        children: t('CANCEL'),
        onClick: hide,
      }, {
        id: 'add-admin-alert-add',
        primary: true,
        children: t('ADD'),
        onClick: addOneAdmin,
      }, {
        id: 'add-admin-alert-continue',
        primary: true,
        children: `${t('ADD')} & ${t('CONTINUE')}`,
        onClick: addManyAdmin,
      }];

    const handleUserName = e => {
        setResponseMsg('');
        setNewAdmin(e);
    };

    const alertActions = useCallback(data => dispatch({ type: 'alertPopup', payload: data }), [dispatch]);

    const hideAlert = () => {
        alertActions({ displayAlert: false, alertActions: {} });
    };

    const deleteAdmin = id => {
        const deleteAdminAttempt = middlewares.deleteAdmin(id);
        deleteAdminAttempt.then(res => {
            setList(res.user);
            hideAlert();
        });
    };

    const showDeleteAlert = (e, id) => {
        const actions = {
            id: 'delete-admin-alert',
            showConfirmButton: true,
            msg: t('DELETE_CONFIRM_MSG'),
            okBtn: deleteAdmin,
            cancelBtn: hideAlert,
            callBackProps: id,
        };
        alertActions({ displayAlert: true, alertActions: actions });
    };

    return (
        <div style={{ width: '100%' }} id="admin-list">
            <div className="text-center">
                <h1 className="text-center">Admin</h1>
                <Button raised primary onClick={() => { goToRettsSim(); }}>RETTS-Sim</Button>
            </div>
            <h2 className="categoryHeading">{t('LIST_OF_ADMINS')}</h2>
            <div className="AdminListContainer">
                <DataTable plain>
                    <TableHeader>
                        <TableRow>
                            <TableColumn/>
                            <TableColumn>{t('USERNAME')}</TableColumn>
                            <TableColumn>{t('ROLE')}</TableColumn>
                            <TableColumn>{t('UPDATED_BY')}</TableColumn>
                            <TableColumn>{t('DELETE')}</TableColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            _map(list, (item, i) => (
                                <TableRow key={i}>
                                    <TableColumn>{i + 1}</TableColumn>
                                    <TableColumn className="adminUser">{item.username}</TableColumn>
                                    <TableColumn className="capitalize">{item.role}</TableColumn>
                                    <TableColumn>
                                        {item.lastUpdatedBy}
                                    </TableColumn>
                                    <TableColumn>
                                        <Button className="deleteAdmin" icon disabled={userDetails && item.username === userDetails.username} secondary iconChildren="delete_sweep" onClick={e => showDeleteAlert(e, item._id)}/>
                                    </TableColumn>
                                </TableRow>
                              )
                            )
                        }
                    </TableBody>
                </DataTable>
                <Button flat primary iconChildren="add" id="add_admin" tooltipLabel="Add New Admin" tooltipPosition="top" onClick={hide}>{t('ADD')}</Button>

            </div>
            {visible && (
            <div className="DialogContainer">
                <Dialog
                    id="add-new-admin-dialog"
                    title={t('ADD_NEW_ADMIN')}
                    actions={actions}
                    contentClassName="md-grid"
                    >
                    <AddAdminForm
                        handleUserName={handleUserName}
                        responseMsg={responseMsg}/>
                </Dialog>
            </div>
        )}

        </div>
    );
};

export default withTranslation()(ActiveAdmin);
