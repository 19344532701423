/* eslint-disable react/prop-types */
import React from 'react';
import './ProgressBar.scss';
import { withTranslation } from 'react-i18next';

const ProgressBar = ({ status }) => {
    const percentageLevel = { NOT_FINISHED: 50, NOT_ATTENDED: 0, PASSED: 100 };
    const color = (status === 'NOT_FINISHED') ? '#0090ff' : '#0d7409';

    return (
        <div className="quiz-progressbar">
            <div className="progressBar">
                <div className="progressBarActive" style={{ width: `${percentageLevel[status]}%`, background: `${color}` }}/>
            </div>
        </div>
    );
};

export default withTranslation()(ProgressBar);
