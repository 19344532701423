/* eslint-disable react/prop-types */
import React from 'react';
import _filter from 'lodash/filter';

const VpACategory = props => {
    const { vpData, renderCheckBox } = props;

    const essRisklevel1 = _filter(vpData, { essRisklevel: 1 });
    const essRisklevel2 = _filter(vpData, { essRisklevel: 2 });
    const essRisklevel3 = _filter(vpData, { essRisklevel: 3 });
    const essRisklevel4 = _filter(vpData, { essRisklevel: 4 });

    return (
        <div className="grid-example">
            <div className="cell size1 ">A</div>
            <div className="cell size2 side-riskLevel1-border top-riskLevel1-border">
                {renderCheckBox(essRisklevel1)}
            </div>
            <div className="cell size2 side-riskLevel2-border top-riskLevel2-border">
                {renderCheckBox(essRisklevel2)}
            </div>
            <div className="cell size2 side-riskLevel3-border top-riskLevel3-border">
                {renderCheckBox(essRisklevel3)}
            </div>
            <div className="cell size2 side-riskLevel4-border top-riskLevel4-border">
                {renderCheckBox(essRisklevel4)}
            </div>
        </div>
    );
};

export default VpACategory;
