export const tagsInitialState = {
    tags: {
      list: {},
    }
  };

export const tagsActions = {
    setTags: (state, payload) => {
        const tags = { list: payload };

        return { tags };
      },
};
