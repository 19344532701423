/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Autocomplete, Button, TextField, FontIcon, SelectField } from 'react-md';
import './AddQuestions.scss';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _uniq from 'lodash/uniq';
import _remove from 'lodash/remove';
import { middlewares } from '../../../../../../middlewares';
import { useStore } from '../../../../../../store.js';
import AddNewCategory from './AddNewCategory';

const AddQuestions = props => {
    const { t, update, close } = props;
    const {
        state: {
          tags: { list },
          user: { userDetails },
          questions: { newQuestion, productList, packagesList },
        },
        dispatch
    } = useStore();

    const tagList = _map(list, item => item.tagname);
    const setNewQuestionValue = useCallback((data, key) => dispatch({ type: 'setNewQuestionValues', payload: { data, key } }), [dispatch]);
    const resetQuestionForm = useCallback(() => dispatch({ type: 'resetQuestion', payload: {} }), [dispatch]);
    const alertActions = useCallback(data => dispatch({ type: 'alertPopup', payload: data }), [dispatch]);
    const setQuestions = useCallback(data => dispatch({ type: 'setQuestions', payload: data }), [dispatch]);
    const setEssId = useCallback(data => dispatch({ type: 'setEssId', payload: data }), [dispatch]);
    const setVpId = useCallback(data => dispatch({ type: 'setVpId', payload: data }), [dispatch]);

    const addTags = val => {
        const tags = newQuestion.tags;
        setNewQuestionValue(_uniq(tags.concat([val])), 'tags');
    };

    const removeTags = (e, val) => {
        const tags = newQuestion.tags;
        setNewQuestionValue(_remove(tags, item => item !== val), 'tags');
    };

    const saveQuestionForm = () => {
        middlewares.addNewQuestion({ newQuestion, name: userDetails.username })
        .then(res => {
            const actions = {
                showConfirmButton: true,
                msg: t(res.msg),
                isShowBtns: false
            };
            if (res.validation) {
                if (!res.error) {
                    resetQuestionForm();

                    if (res.data) {
                        setQuestions(res.data);
                    }
                }
            }

            alertActions({ displayAlert: true, alertActions: actions });

            setTimeout(() => alertActions({ displayAlert: false, alertActions: {} }), 1000);
        });
    };

    const stucturedProductList = _map(productList, list => ({ value: list.id, label: list.type }));
    const stucturedVeriosnList = _map(newQuestion.productVersionList, (val, i) => ({ key: i, value: val, label: val }));
    const stucturedProductLangList = _map(newQuestion.productLangList, val => ({ key: val.id, value: val.languageName, label: val.languageName }));

      const handleProductName = val => {
        setNewQuestionValue(val, 'selectedProduct');
        const fetchVersions = async () => {
            const versions = await middlewares.getAllVersionsByProduct(val)
            .then(list => list);
            setNewQuestionValue(versions, 'productVersionList');
        };

        fetchVersions();
      };

      const handleVersion = val => {
        setNewQuestionValue(val, 'selectedVersion');
        setNewQuestionValue('', 'selectedLang');
        const fetchLang = async () => {
            const lang = await middlewares.getAllLangByVersion(val, newQuestion.selectedProduct)
            .then(list => list);
            setNewQuestionValue(lang, 'productLangList');
        };

        fetchLang();
      };

      const handleLanguage = val => {
        setNewQuestionValue(val, 'selectedLang');

        const productName = _filter(stucturedProductList, { value: newQuestion.selectedProduct })[0].label;

        if (packagesList) {
            const notReadypackageId = _filter(packagesList.notReady, { version: newQuestion.selectedVersion, type: productName, languageName: val });
            const publishedpackageId = _filter(packagesList.published, { version: newQuestion.selectedVersion, type: productName, languageName: val });

            if (!notReadypackageId.length) {
                const { id, vppackageId } = publishedpackageId[0];
                setEssId(id);
                setVpId(vppackageId);

                return true;
            }
                // eslint-disable-next-line no-alert
                alert(t('UNPUBLISHED_PACKAGE_MSG'));

                return false;
        }

        return false;
      };

    return (
        <div className="AddQuestionsContainer">
            <div className="innerContainer">
                <div className="questionContainer">
                    <h1 className="categoryHeading text-center">{t('CREATE_NEW_QUESTION')}</h1>
                    <h2 className="categoryHeading">{t('CHOOSE_RETTS_VERSION')}</h2>
                    <div className="veriosnDialogBox qCategory">
                        <label htmlFor="product-name">{t('PRODUCTS')}</label>
                        <SelectField
                            id="product-name"
                            // label={t('PRODUCTS')}
                            className="md-cell"
                            onChange={handleProductName}
                            menuItems={stucturedProductList}
                            value={newQuestion.selectedProduct}
                            />
                        <label htmlFor="Version">{t('VERSION')}</label>
                        <SelectField
                            id="Version"
                            // label={t('VERSION')}
                            className="md-cell"
                            disabled={!newQuestion.productVersionList.length}
                            onChange={handleVersion}
                            menuItems={stucturedVeriosnList}
                            value={newQuestion.selectedVersion}
                            />
                        <label htmlFor="Language">{t('LANGUAGE')}</label>
                        <SelectField
                            id="Language"
                            // label={t('LANGUAGE')}
                            className="md-cell"
                            disabled={!newQuestion.productLangList.length}
                            onChange={handleLanguage}
                            menuItems={stucturedProductLangList}
                            value={newQuestion.selectedLang}
                            />
                    </div>
                    <h2 className="categoryHeading">{t('CREATE_QUESTION')}</h2>
                    <div className="qCategory">
                        <h3 className="categoryHeading">{t('INTRODUCTION')}</h3>
                        <label htmlFor="title">{t('QUESTION_TITLE')}</label>
                        <TextField
                            id="title"
                            // label={t('QUESTION_TITLE')}
                            type="text"
                            required
                            value={newQuestion.title}
                            onChange={e => setNewQuestionValue(e, 'title')}
                            className="md-cell md-cell--12" />
                        <label htmlFor="tagListContainer">{`${t('ADD')} ${t('TAGS')}`}</label>
                        <Autocomplete
                            id="tagListContainer"
                            // label={`${t('ADD')} ${t('TAGS')}`}
                            className="md-cell md-cell--4"
                            onAutocomplete={addTags}
                            clearOnAutocomplete={true}
                            data={tagList}
                        />
                        <div className="tagListContainer">
                            {
                            _map(newQuestion.tags, tag => (
                                <div className="tags" key={tag}>
                                    {tag}
                                    <FontIcon
                                        onClick={e => removeTags(e, tag)}>
                                        clear
                                    </FontIcon>
                                </div>
                            ))
                        }
                        </div>
                        <label htmlFor="patientSituation">{t('SITUATIONS_AND_BACKGROUND')}</label>
                        <TextField
                            id="patientSituation"
                            // label={t('SITUATIONS_AND_BACKGROUND')}
                            type="text"
                            rows={5}
                            required
                            value={newQuestion.pSituation}
                            onChange={e => setNewQuestionValue(e, 'pSituation')}
                            className="md-cell md-cell--12" />
                        <label htmlFor="patientSymptoms">{t('SYMPTOMS')}</label>
                        <TextField
                            id="patientSymptoms"
                            // label={t('SYMPTOMS')}
                            type="text"
                            rows={5}
                            required
                            value={newQuestion.pSymptoms}
                            onChange={e => setNewQuestionValue(e, 'pSymptoms')}
                            className="md-cell md-cell--12" />
                    </div>
                    <h3 className="categoryHeading">{t('VITAL_SIGNS')}</h3>
                    <AddNewCategory itemList={newQuestion.pConditions} keyValue="pConditions"/>
                    <h2 className="categoryHeading">{t('ANSWERS')}</h2>
                    <AddNewCategory itemList={newQuestion.results} keyValue="results"/>
                </div>
                <div className="footer">
                    {!newQuestion.isEdited ? (
                        <div>
                            <Button flat secondary onClick={resetQuestionForm}>{t('RESET')}</Button>
                            <Button flat primary onClick={saveQuestionForm}>{t('SAVE')}</Button>
                        </div>
) : (
    <div>
        <Button flat secondary onClick={close}>{t('CLOSE')}</Button>
        <Button flat primary onClick={update}>{t('UPDATE')}</Button>
    </div>
)}
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(AddQuestions);
