/* eslint-disable */
import React, {useCallback, useState} from 'react'
import {withTranslation} from 'react-i18next'
import {
    FontIcon,
    SelectField
} from 'react-md'
import {useStore} from '../../store.js'
// eslint-disable-next-line import/no-named-as-default-member
import Constants from '../../Utils/Constants'

const Menu = props => {
    const {i18n, t} = props
    const [Lang, setLang] = useState(localStorage.getItem('i18nextLng') || 'sv')

    const {
        state: {
            user: {loggedIn, userRole, view}
        },
        dispatch
    } = useStore()

    const logout = useCallback(() => dispatch({type: 'logout', payload: {}}), [dispatch])
    const setView = useCallback(data => dispatch({type: 'setView', payload: data}), [dispatch])
    const setPath = useCallback(data => dispatch({type: 'setPath', payload: data}), [dispatch])
    const login = useCallback(() => dispatch({type: 'login', payload: {}}), [dispatch])

    const handleLogout = () => {
        logout()
    }

    const handleLogin = () => {
        login()
    }

    const handleView = () => {
        view === 'admin' ? setView('student') : setView('admin')
    }

    const changeLanguage = language => {
        localStorage.setItem('i18nextLng', language)
        setLang(language)
        i18n.changeLanguage(language)
    }

    const languageList = Constants.getSupportedLanguages().map(lang => ({ label: lang.label, value: lang.value }))

    const goToHomePage = () => {
        setPath('/rettsacademy')
    }

    const goToAdminPage = () => {
        setPath('/admin')
    }

    return (
        <div>
            <ul className="MenuContainer">
                <li>
                    <FontIcon className='margin-right-min25'>language</FontIcon>
                    <SelectField
                        id="select-field-6"
                        onChange={changeLanguage}
                        value={Lang}
                        className="md-cell language-dropdown"
                        menuItems={languageList}
                        itemLabel="label"
                        itemValue="value"
                        position={SelectField.Positions.BELOW}
                        simplifiedMenu={true}
                    />
                </li>
                {userRole === 'admin' ? <li id="admin_div" onClick={goToAdminPage}>{t('SETTINGS')}</li> : <li/>}
                {(loggedIn && userRole === 'admin')
                    ? (
                        <li>
                            <div onClick={handleView}>
                                {t('VIEW_AS')}
                                &nbsp;
                                {view === 'admin'
                                    ? <span>{t('STUDENT')}</span> : <span>{t('ADMIN')}</span>}
                                {' '}
                            </div>
                        </li>
                    ) : null}
                {loggedIn
                    ? (<li>
                        <div id="logout_div" onClick={handleLogout}>{t('LOGOUT')}</div>
                    </li>)
                    : (<li>
                        <div onClick={handleLogin}>{t('LOGIN')}</div>
                    </li>)
                }
            </ul>
        </div>
    )
}

export default withTranslation()(Menu)
