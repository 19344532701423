/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, TextField, FontIcon, Grid, Cell, SelectionControlGroup } from 'react-md';
import './AddQuestions.scss';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _concat from 'lodash/concat';
import _uniq from 'lodash/uniq';
import _remove from 'lodash/remove';
import EssContainer from './EssContainer';
import { middlewares } from '../../../../../../middlewares';
import { useStore } from '../../../../../../store.js';

const AddNewCategory = props => {
    const { itemList, keyValue, visisble = false, t } = props;
    const {
        state: {
            questions: { newQuestion: { essPackageId, selectedEssData } }
        },
        dispatch
    } = useStore();

    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');
    const [essList, setEssList] = useState([]);
    const [selectedEss, setSelectedEss] = useState({});
    const [visibleNewRow, setVisibleNewRow] = useState(visisble);

    const setNewQuestionValue = useCallback((data, key) => dispatch({ type: 'setNewQuestionValues', payload: { data, key } }), [dispatch]);
    const setNewRow = useCallback((data, key) => dispatch({ type: 'addNewRow', payload: { data, key } }), [dispatch]);
    const setRemoveRow = useCallback((data, key) => dispatch({ type: 'removeRow', payload: { data, key } }), [dispatch]);

    const handleNewKey = e => setNewKey(e);

    const handleNewValue = e => setNewValue(e);

    const removeNewRow = () => {
        setVisibleNewRow(false);
        setNewValue('');
        setNewKey('');
    };

    const removeQRows = index => {
        setRemoveRow(index, keyValue);
    };

    const handleEssSearchPhase = searchPhase => {
        const fetchLang = async () => {
            const ess = await middlewares.searchEssPackages(essPackageId, searchPhase);
            setEssList(ess);
        };

        fetchLang();
    };

    const addNewRow = () => {
        if ((newKey || (keyValue === 'symptoms')) && newValue) {
            setNewRow({ key: newKey ? newKey : itemList.length + 1, value: newValue, label: newKey ? newKey : itemList.length + 1 }, keyValue);
            setVisibleNewRow(false);
            setNewValue('');
            setNewKey('');
        }
    };

    const handleSelectedEss = searchPhrase => {
        setSelectedEss(_filter(essList, { searchPhrase }));

        const fetchLang = async () => {
            const data = await middlewares.getEssDataById(_filter(essList, { searchPhrase })[0].id);
            setNewQuestionValue(data, 'selectedEssData');
            setNewQuestionValue([], 'selectedEssSymptomsList');
        };

        fetchLang();
    };

    const handleMultiArrayValue = (item, i) => {
        const value = document.getElementById(item.key).value;
        if (value) {
            const concatValue = _uniq(_concat(item.value, document.getElementById(item.key).value));
            setNewQuestionValue(concatValue, { category: { key: keyValue, index: i, cKey: item.key } });
            document.getElementById(item.key).value = '';
        }
    };

    const removeMultiArrayValue = (item, i, selectedArray) => {
        const values = item.value;
        const removedValues = _remove(values, n => n !== selectedArray);
        setNewQuestionValue(removedValues, { category: { key: keyValue, index: i, cKey: item.key } });
    };

    return (
        <div className="qCategory">
            {_map(itemList, (item, i) => (
                <Grid className="qRows" size={10} key={i}>
                    {(keyValue !== 'symptoms') && (!item.multiSelectionDropDown && !item.risklevel && !item.multipleAnswers) && (
                    <Cell className="qRowsLeft">
                        <span>
                            {t(item.label)}
:
                        </span>
                    </Cell>
                    )}

                    {(!item.multiSelectionDropDown && !item.risklevel && !item.multipleAnswers) && (
                    <Cell className="remainingWidth">
                        <TextField
                            id="i"
                            type="text"
                            rows={1}
                            value={item.value}
                            onChange={e => setNewQuestionValue(e, { category: { key: keyValue, index: i, cKey: item.key } })}
                            />
                    </Cell>
                    )}

                    {item.risklevel && (
                    <Cell className="qRowsLeft" size={1}>
                        <span>
                            {t(item.label)}
:
                        </span>
                    </Cell>
                    )}

                    {item.risklevel && (
                        <Cell size={8}>
                            <SelectionControlGroup
                                id={item.key}
                                type="radio"
                                onChange={e => setNewQuestionValue(e, { category: { key: keyValue, index: i, cKey: item.key } })}
                                value={item.value}
                                checkedRadioIcon={<FontIcon>check_box</FontIcon>}
                                uncheckedRadioIcon={<FontIcon>check_box_outline_blank</FontIcon>}
                                controls={item.controls}/>
                        </Cell>
                    )}

                    {item.multipleAnswers && (
                        <div className="multipleAnswerOuterContainer" >
                            <Cell className="qRowsLeft" size={1}>
                                <span>
                                    {t(item.label)}
:
                                </span>
                            </Cell>
                            <Cell className="multipleAnswerContainer" size={12}>
                                <TextField
                                    id={item.key}
                                    type="text"
                                    />
                                <div className="addIcon">
                                    <Button flat primary iconChildren="add" onClick={() => handleMultiArrayValue(item, i)}>{t('ADD')}</Button>
                                </div>
                            </Cell>
                            <Cell className="multipleAnswerContainer" size={12}>
                                <div>
                                    {_map(item.value, (num, index) => (
                                        <div key={index} className="tags">
                                            {num}
                                            <FontIcon onClick={() => { removeMultiArrayValue(item, i, num); }}>clear</FontIcon>
                                        </div>
                                      ))}
                                </div>
                            </Cell>
                        </div>
                    )}

                    {item.multiSelectionDropDown && (
                    <EssContainer
                        handleSelectedEss={handleSelectedEss}
                        handleEssSearchPhase={handleEssSearchPhase}
                        essList={essList}
                        setSelectedEss={setSelectedEss}
                        selectedEss={selectedEss}
                        selectedEssData={selectedEssData}/>
                    )}
                    <Cell className="qBtns" size={2}>
                        {(!item.multiSelectionDropDown && !item.risklevel && !item.multipleAnswers)
                            && <Button icon primary iconChildren="clear" onClick={() => removeQRows(i)}/>}
                    </Cell>
                </Grid>
))}

            {visibleNewRow && (
                <Grid className="qRows" size={10}>
                    {(keyValue !== 'symptoms') && (
                    <Cell className="qRowsLeft" size={1}>
                        <span>
                            <TextField
                                id="newQValue"
                                type="text"
                                value={newKey}
                                onChange={handleNewKey}/>
:
                        </span>
                    </Cell>
)}
                    <Cell size={8}>
                        <TextField
                            id="newQValue"
                            type="text"
                            value={newValue}
                            onChange={handleNewValue}/>
                    </Cell>
                    <Cell className="qBtns" size={2}>
                        <Button primary icon iconChildren="clear" onClick={() => removeNewRow()} />
                        <Button primary icon iconChildren="done" onClick={() => addNewRow()} />
                    </Cell>
                </Grid>
            )}

            <div className="addIcon">
                <Button flat primary iconChildren="add" onClick={() => setVisibleNewRow(true)}>{t('ADD')}</Button>
            </div>
        </div>
    );
};

export default withTranslation()(AddNewCategory);
