import ReactCardFlip from 'react-card-flip';
import { FileInput, FontIcon, TextField } from 'react-md';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { middlewares } from '../../../../middlewares';

const CourseCard = props => {
    const [flipped, setFlipped] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [error, setError] = useState('');
    const [title, setTitle] = useState('');
    const [Img, setImg] = useState();

    const {
        t,
        course,
        key,
        showAdminViews,
        setCourseList,
        getSelectedCourse,
        deleteCourse
    } = props;

    const handleEditImage = e => {
        setError('');
        if (e) {
            if (e.size > 50000) {
                setError(t('MAX_IMG_SIZE_EXCEED_ERR'));
                setImg('');
            } else {
                setImg(e);
            }
        } else {
            setImg('');
        }
    };

    const handleFlipped = course => {
        setTitle('');
        setFlipped(!flipped);
        setImg('');
        setError('');
        setTimeout(() => {
            setTitle(course.coursetitle);
        }, 200);
    };

    const editCourse = id => {
        setUpdating(true);
        setError('');
        const formData = new FormData();
        formData.set('title', title);
        formData.set('id', id);
        if (Img && Img.name) {
            formData.append('myImage', Img);
        }
        const uploadFile = middlewares.editCourse(formData);
        uploadFile.then(res => {
            if (!res.err) {
                setImg('');
                setUpdating(false);
                setCourseList(res);
                setFlipped(false);
            } else {
                setUpdating(false);
                setError(res.err);
            }
        });
    };

    return (
        <ReactCardFlip key={key} data-id={key} isFlipped={flipped}>
            <div data-id={key} className="courseBox" style={{ backgroundImage: `url(data:image/gif;base64,${course.img.split(';base64,').pop()})` }}>
                <div className="courseTitle" data-id={key} onClick={() => getSelectedCourse(course.id)}>
                    {course.coursetitle}
                    { showAdminViews && (<> <br /> {`(${course.language})`}</>) }
                </div>
                {showAdminViews && (
                    <div className="sideBar">
                        <FontIcon onClick={() => deleteCourse(course.id)}>delete</FontIcon>
                        <FontIcon onClick={() => handleFlipped(course)}>edit</FontIcon>
                    </div>
                )}
                <div className="progressBar">
                    <div className="progressBarActive" style={{ width: `${course.percentcalulate}%` }}/>
                </div>
            </div>
            <div className="courseBox" data-id={key} >
                {isUpdating && <div className="editOverlay">{t('UPDATING')}...</div>}
                <div className="editCourse" >
                    <div className="md-cell md-cell--12 selectBtn">
                        <label id="title" htmlFor={`title-${key}`}>{`${t('UPDATE_COURSE_TITLE')}:`}</label>
                        <TextField
                            id={`title-${key}`}
                            name={`title-${key}`}
                            type="text"
                            value={title}
                            onChange={e => { setTitle(e); }} />
                    </div>
                    <div className="md-cell md-cell--12 selectBtn">
                        <FileInput
                            id="updateimage-input-4"
                            name="updateimage"
                            raised="true"
                            primary
                            accept="image/*"
                            allowDuplicates
                            label={t('UPDATE_IMG')}
                            onChange={handleEditImage}
                        />
                        <br/>
                        <span className="msg">{t('MAX_IMG_SIZE_MSG')}</span>
                    </div>
                    {Img && Img.name && (
                        <div className="file_detail md-cell md-cell--6">
                            {`Selected Image: ${Img.name}`}
                            <FontIcon onClick={() => setImg('')}>delete</FontIcon>
                        </div>
                    )}
                    {error && (
                        <div className="error">
                            {error}
                        </div>
                    )}
                </div>
                {showAdminViews && (
                    <div className="sideBar">
                        <FontIcon onClick={() => setFlipped(false)} >close</FontIcon>
                        <FontIcon onClick={() => editCourse(course.id)} >done</FontIcon>
                    </div>
                )}
                <div className="progressBar">
                    <div className="progressBarActive" style={{ width: `${course.percentcalulate}%` }}/>
                </div>
            </div>
        </ReactCardFlip>
    );
};

export default withTranslation()(CourseCard);
