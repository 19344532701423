import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import detector from './Utils/languageDetector';

const languageDetector = new LanguageDetector();
languageDetector.addDetector(detector);

i18n
    .use(Backend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            order: ['supportedLanguageDetector'],
        },
        fallbackLng: ['sv'],
        // have a retts namespace used around the full app
        ns: ['common', 'academy'],
        defaultNS: ['common', 'academy'],
        fallbackNS: ['common', 'academy'],
        debug: false,
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        interpolation: {
            escapeValue: false, // not needed for react!!
        },

        react: {
            wait: true,
        }
    });

export default i18n;
