import axios from 'axios';
import _ from 'lodash';

const createQuiz = data => {
    return axios.post(`${process.env.REACT_APP_API_BASEURL}/quiz`,
    { data },
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const getAllQuiz = async () => {
    if (JSON.parse(localStorage.getItem('roleId')) === 35) {
        if (!sessionStorage.getItem('quizList')) {
            const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/quiz`,
            { headers: { accessToken: localStorage.getItem('accessToken') } });
                sessionStorage.setItem('quizList', JSON.stringify(response.data));
        }
        const quizList = JSON.parse(sessionStorage.getItem('quizList'));

        return quizList;
    }

    return axios.get(`${process.env.REACT_APP_API_BASEURL}/quiz`,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    })
    .catch(() => []);
};

const deleteQuiz = data => {
    return axios.put(`${process.env.REACT_APP_API_BASEURL}/quiz`,
    { data },
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const editQuizTitle = data => {
    console.log('==hit');

    return axios.post(`${process.env.REACT_APP_API_BASEURL}/quiz/${data.id}`,
    { data: data.title },
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const setQuizStatus = async data => {
    if (JSON.parse(localStorage.getItem('roleId')) === 35) {
        if (!sessionStorage.getItem('quizList')) {
            const response = await axios.get(`${process.env.REACT_APP_API_BASEURL}/quiz`,
            { headers: { accessToken: localStorage.getItem('accessToken') } });
                sessionStorage.setItem('quizList', JSON.stringify(response.data));
        }
        const quizList = JSON.parse(sessionStorage.getItem('quizList'));
        _.forEach(quizList, quiz => {
            if (quiz._id === data.id) {
                quiz.status = data.status;
                quiz.saved = data.saved;
                quiz.savedQuestions = data.savedQuestions;
            }
        });
        sessionStorage.setItem('quizList', JSON.stringify(quizList));

        return quizList;
    }

    return axios.put(`${process.env.REACT_APP_API_BASEURL}/quiz`,
    data,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};


const QuizServices = {
    createQuiz,
    getAllQuiz,
    deleteQuiz,
    setQuizStatus,
    editQuizTitle,
};

export { QuizServices };
