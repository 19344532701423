import axios from 'axios';

const getAllTags = () => {
    return axios.get(`${process.env.REACT_APP_API_BASEURL}/tags`,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const addNewTag = tagname => {
    return axios.post(`${process.env.REACT_APP_API_BASEURL}/tags`,
        { tagname },
        { headers: { accessToken: localStorage.getItem('accessToken') } }
    )
    .then(response => {
        return response.data;
    });
};

const deleteTag = id => {
    return axios.delete(`${process.env.REACT_APP_API_BASEURL}/tags/${id}`,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const TagsServices = {
    getAllTags,
    addNewTag,
    deleteTag
};

export { TagsServices };
