import React, { useState } from 'react';
import Category from './Category';
import Tags from './Tags';
import AddQuestions from './AddQuestions';
import ManageQuestions from './ManageQuestions';
// import ManageQuestionVersion from './ManageQuestionVersion/index.jsx';

const Questions = () => {
    const [selectedSection, setSelectedSection] = useState(4);
    const renderSelectedSection = () => {
        let section;
        switch (selectedSection) {
            case 1:
                section = <AddQuestions/>;
                break;
            case 2:
                section = <ManageQuestions/>;
                break;
            case 3:
                section = <Tags/>;
                break;
            default:
                section = <AddQuestions/>;
                break;
        }

        return section;
    };

    const handleSection = sectionId => {
        setSelectedSection(sectionId);
    };

    return (
        <div className="ContentContainer">
            <Category handleSection={handleSection}/>
            {renderSelectedSection()}
        </div>
    );
};

export default Questions;
