/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'react-md';
import QuizLayout from '../QuizLayout';
import { middlewares } from '../../../../../middlewares';
import { useStore } from '../../../../../store';
import './ViewQuiz.scss';

const ViewQuiz = props => {
    const [isVisibleQuizLayout, setVisibleQuizLayout] = useState(false);
    const { quizData, t } = props;
    const {
        dispatch
      } = useStore();

    const setQuizList = useCallback(data => dispatch({ type: 'setQuizList', payload: data }), [dispatch]);

    const handleStartQuiz = async visibility => {
        setVisibleQuizLayout(visibility);
        const newQuizList = await middlewares.setQuizStatus({ status: 'NOT_FINISHED', id: quizData._id })
        .then(list => list);
        setQuizList(newQuizList);
    };

    const handleContinueLater = async savedQuestionNo => {
        const data = {
            id: quizData._id,
            status: 'NOT_FINISHED',
            saved: true,
            savedQuestions: savedQuestionNo
        };
        const newQuizList = await middlewares.setQuizStatus(data)
        .then(list => {
            setVisibleQuizLayout(false);

            return list;
        });
        setQuizList(newQuizList);
        props.onBack(newQuizList);
    };

    return (
        <div>
            {quizData && (
            <div>
                {!isVisibleQuizLayout
                    ? (
                        <div className="QuizContainer">
                            <div className="wrapper">
                                <div className="innerContainer">
                                    <h2>{quizData.title}</h2>
                                    <div className="quizDescription">{quizData.description}</div>
                                    <div className="questionCount">
                                        <span>
                                            {t('NUMBER_OF_QUESTIONS')}
                                            {' '}
:
                                            {' '}
                                        </span>
                                        <span>{quizData.selectedQuestions.length}</span>
                                    </div>
                                </div>
                                <div className="footer">
                                    <Button
                                        flat
                                        secondary
                                        onClick={props.onBack}>
                                        {t('BACK')}
                                    </Button>
                                    <Button
                                        flat
                                        primary
                                        onClick={() => handleStartQuiz(true)}>
                                        {t('START_QUIZ')}
                                    </Button>
                                </div>
                            </div>
                        </div>
) : (
    <QuizLayout
        handleContinueLater={handleContinueLater}
        onComplete={() => props.onBack(quizData)}
        handleStartQuiz={handleStartQuiz}
        questionList={quizData}/>
)}
            </div>
)}
        </div>
    );
};

export default withTranslation()(ViewQuiz);
