import _map from 'lodash/map';
import _filter from 'lodash/filter';

export const questionInitialState = {
    questions: {
        quesList: [],
        newQuestion: {
            title: '',
            tags: [],
            isEdited: false,
            pSituation: '',
            pSymptoms: '',
            selectedProduct: '',
            selectedVersion: '',
            selectedLang: '',
            selectedEssData: {},
            selectedEssSymptomsList: [],
            essPackageId: '',
            vpPackageId: '',
            productVersionList: [],
            productLangList: [],
            pConditions: [
                {
                  key: 'A',
                  label: 'A',
                  value: ''
                },
                {
                  key: 'B',
                  label: 'B',
                  value: ''
                },
                {
                  key: 'C',
                  label: 'C',
                  value: ''
                },
                {
                  key: 'D',
                  label: 'D',
                  value: ''
                },
                {
                  key: 'E',
                  label: 'E',
                  value: ''
                }
            ],
            results: [
                {
                  key: 'Prio vital signs',
                  label: 'VITAL_SIGNS',
                  value: '',
                  risklevel: true,
                  controls: [{
                    value: 'vp_risklevel_1',
                    name: 'vp_risklevel_1',
                    className: 'risklevel_1',
                    label: ''
                  }, {
                    value: 'vp_risklevel_2',
                    name: 'vp_risklevel_2',
                    className: 'risklevel_2',
                    label: ''
                  }, {
                    value: 'vp_risklevel_3',
                    name: 'vp_risklevel_3',
                    className: 'risklevel_3',
                    label: ''
                  }, {
                    value: 'vp_risklevel_4',
                    name: 'vp_risklevel_4',
                    className: 'risklevel_4',
                    label: ''
                  }]
                },
                {
                  key: 'ESS',
                  label: 'ESS',
                  value: '',
                  multiSelectionDropDown: true
                },
                {
                  key: 'Prio Ess',
                  label: 'ESS',
                  value: '',
                  risklevel: true,
                  controls: [{
                    value: 'ess_risklevel_1',
                    name: 'ess_risklevel_1',
                    className: 'risklevel_1',
                    label: ''
                  }, {
                    value: 'ess_risklevel_2',
                    name: 'ess_risklevel_2',
                    className: 'risklevel_2',
                    label: ''
                  }, {
                    value: 'ess_risklevel_3',
                    name: 'ess_risklevel_3',
                    className: 'risklevel_3',
                    label: ''
                  }, {
                    value: 'ess_risklevel_4',
                    name: 'ess_risklevel_4',
                    className: 'risklevel_4',
                    label: ''
                  }]
                },
                {
                  key: 'Process',
                  label: 'PROCESS',
                  value: '',
                },
                // {
                //   key: 'Symptoms',
                //   label: 'SYMPTOMS',
                //   value: '',
                // },
                {
                  key: 'Diagnosis',
                  label: 'DIAGNOSIS',
                  value: ''
                },
                {
                  key: 'Facts and course of events',
                  label: 'FACTS_&_COURSE_OF_EVENTS',
                  value: ''
                },
                {
                  key: 'Differential diagnosis',
                  label: 'DIFFERENTIAL DIAGNOSIS',
                  value: ''
                },
                {
                  key: 'Final prio',
                  label: 'FINAL_PRIO',
                  value: '',
                  risklevel: true,
                  controls: [{
                    value: 'final_risklevel_1',
                    name: 'final_risklevel_1',
                    className: 'risklevel_1',
                    label: ''
                  }, {
                    value: 'final_risklevel_2',
                    name: 'final_risklevel_2',
                    className: 'risklevel_2',
                    label: ''
                  }, {
                    value: 'final_risklevel_3',
                    name: 'final_risklevel_3',
                    className: 'risklevel_3',
                    label: ''
                  }, {
                    value: 'final_risklevel_4',
                    name: 'final_risklevel_4',
                    className: 'risklevel_4',
                    label: ''
                  }]
                },
            ]
        },
        filteredQuestions: [],

    }
};

const defualtNewQuestionState = {
  title: '',
  tags: [],
  isEdited: false,
  pSituation: '',
  pSymptoms: '',
  selectedProduct: '',
  selectedVersion: '',
  selectedLang: '',
  selectedEssData: {},
  selectedEssSymptomsList: [],
  essPackageId: '',
  vpPackageId: '',
  productVersionList: [],
  productLangList: [],
  pConditions: [
      {
        key: 'A',
        label: 'A',
        value: ''
      },
      {
        key: 'B',
        label: 'B',
        value: ''
      },
      {
        key: 'C',
        label: 'C',
        value: ''
      },
      {
        key: 'D',
        label: 'D',
        value: ''
      },
      {
        key: 'E',
        label: 'E',
        value: ''
      }
  ],
  results: [
      {
        key: 'Prio vital signs',
        label: 'PRIO_VP',
        value: '',
        risklevel: true,
        controls: [{
          value: 'vp_risklevel_1',
          name: 'vp_risklevel_1',
          className: 'risklevel_1',
          label: ''
        }, {
          value: 'vp_risklevel_2',
          name: 'vp_risklevel_2',
          className: 'risklevel_2',
          label: ''
        }, {
          value: 'vp_risklevel_3',
          name: 'vp_risklevel_3',
          className: 'risklevel_3',
          label: ''
        }, {
          value: 'vp_risklevel_4',
          name: 'vp_risklevel_4',
          className: 'risklevel_4',
          label: ''
        }]
      },
      {
        key: 'ESS',
        label: 'ESS',
        value: '',
        multiSelectionDropDown: true
      },
      {
        key: 'Prio Ess',
        label: 'PRIO_ESS',
        value: '',
        risklevel: true,
        controls: [{
          value: 'ess_risklevel_1',
          name: 'ess_risklevel_1',
          className: 'risklevel_1',
          label: ''
        }, {
          value: 'ess_risklevel_2',
          name: 'ess_risklevel_2',
          className: 'risklevel_2',
          label: ''
        }, {
          value: 'ess_risklevel_3',
          name: 'ess_risklevel_3',
          className: 'risklevel_3',
          label: ''
        }, {
          value: 'ess_risklevel_4',
          name: 'ess_risklevel_4',
          className: 'risklevel_4',
          label: ''
        }]
      },
      {
        key: 'Process',
        label: 'PROCESS',
        value: '',
      },
      // {
      //   key: 'Symptoms',
      //   label: 'SYMPTOMS',
      //   value: '',
      // },
      {
        key: 'Diagnosis',
        label: 'DIAGNOSIS',
        value: ''
      },
      {
        key: 'Facts and course of events',
        label: 'FACTS_&_COURSE_OF_EVENTS',
        value: ''
      },
      {
        key: 'Differential diagnosis',
        label: 'DIFFERENTIAL DIAGNOSIS',
        value: ''
      },
      {
        key: 'Final prio',
        label: 'FINAL_PRIO',
        value: '',
        risklevel: true,
        controls: [{
          value: 'final_risklevel_1',
          name: 'final_risklevel_1',
          className: 'risklevel_1',
          label: ''
        }, {
          value: 'final_risklevel_2',
          name: 'final_risklevel_2',
          className: 'risklevel_2',
          label: ''
        }, {
          value: 'final_risklevel_3',
          name: 'final_risklevel_3',
          className: 'risklevel_3',
          label: ''
        }, {
          value: 'final_risklevel_4',
          name: 'final_risklevel_4',
          className: 'risklevel_4',
          label: ''
        }]
      },
  ]
};

export const questionActions = {

    setNewQuestionValues: (state, payload) => {
      if (!payload.key.category) {
        const val = { ...state.questions.newQuestion, [payload.key]: payload.data };

        return { questions: { ...state.questions, newQuestion: val } };
      }

    const { key, index } = payload.key.category;
    let tempObj = state.questions.newQuestion[key];

    tempObj = _map(tempObj, (item, i) => {
        if (i === index) {
            return { ...item, value: payload.data };
        }

        return item;
    });

    const val = { ...state.questions.newQuestion, [key]: tempObj };

    return { questions: { ...state.questions, newQuestion: val } };
    },

    addNewRow: (state, payload) => {
        const tempObj = state.questions.newQuestion[payload.key];
        const newObj = tempObj.concat(payload.data);

        const val = { ...state.questions.newQuestion, [payload.key]: newObj };

        return { questions: { ...state.questions, newQuestion: val } };
    },

    removeRow: (state, payload) => {
        const tempObj = state.questions.newQuestion[payload.key];
        const data = _filter(tempObj, (items, i) => i !== payload.data && items);
        const newQuestion = state.questions.newQuestion;
        const val = { ...newQuestion, [payload.key]: data };

        return { questions: { ...state.questions, newQuestion: val } };
    },

    resetQuestion: state => {
        return { questions: { ...state.questions, newQuestion: defualtNewQuestionState } };
    },

    setQuestions: (state, payload) => {
        return { questions: { ...state.questions, quesList: payload } };
    },

    setEditedQuestionData: (state, payload) => {
      return { questions: { ...state.questions, newQuestion: payload } };
    },

    setFilteredQuestion: (state, payload) => {
      return { questions: { ...state.questions, filteredQuestions: payload } };
    },

    setPackagesList: (state, payload) => {
      return { questions: { ...state.questions, packagesList: payload } };
    },

    setProductList: (state, payload) => {
      return { questions: { ...state.questions, productList: payload } };
    },

    setEssId: (state, payload) => {
      const val = { ...state.questions.newQuestion, essPackageId: payload };

      return { questions: { ...state.questions, newQuestion: val } };
    },

    setVpId: (state, payload) => {
      const val = { ...state.questions.newQuestion, vpPackageId: payload };

      return { questions: { ...state.questions, newQuestion: val } };
    },
};
