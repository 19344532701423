import React from 'react';
import Constants from '../../../../Utils/Constants';

export function getResourceTitle(resource, language) {
    return resource.title[language];
}
export function getResourceDescription(resource, language) {
    return resource.description[language];
}

export function getLanguage() {
    return localStorage.getItem('i18nextLng');
}

export function getLanguageCode() {
    const [code, variation] = getLanguage().split('-');

    return code;
}

export const languageMapping = Constants.getLanguageMapping();

/**
 * Wraps an anchor tag around a component.
 * @param fileUri The target URI of the anchor
 * @param clickableComponent The component to wrap with the anchor
 * @param download Whether the target should be downloaded instead of navigated to.
 * @returns {JSX.Element}
 */
export function createAnchorElement(fileUri, clickableComponent, download = true) {
    return (
        <a
            href={fileUri}
            download={download}
            style={{ textDecorationLine: 'none' }}
            target={'_blank'}
            rel={'noreferrer'}>
            {clickableComponent}
        </a>
    );
}

export const languageCodes = Object.keys(languageMapping);

export function getLanguageName(langCode) {
    return languageMapping[langCode];
}

export function getTitleOrTranslatedTitle(titleObject, lang, fallbackLang = 'en') {
    if (typeof titleObject === 'string') {
        return titleObject;
    }

    return titleObject[lang] || titleObject[fallbackLang] || '';
}

export function maxOrDefault(numberList, defaultValue = -1) {
    if (numberList === null || numberList === undefined || numberList === []) {
        return defaultValue;
    }

    return Math.max(numberList);
}
