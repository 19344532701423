
import { alertInitialState, alertActions } from './alert';
import { questionInitialState, questionActions } from './question';
import { quizInitialState, quizActions } from './quiz';
import { tagsInitialState, tagsActions } from './tags';
import { userInitialState, userActions } from './user';
import { courseInitialState, courseActions } from './courses';

const initialState = {
    ...alertInitialState,
    ...quizInitialState,
    ...questionInitialState,
    ...userInitialState,
    ...tagsInitialState,
    ...courseInitialState,
};

const Actions = {
    ...alertActions,
    ...quizActions,
    ...questionActions,
    ...tagsActions,
    ...userActions,
    ...courseActions
};

export { initialState, Actions };
