import Constants from './Constants';

const defaultLanguage = 'sv';
const norwegianLanguages = ['nb', 'nn'];

const mergeNorwegianLanguages = lang => {
    return norwegianLanguages.includes(lang) ? 'no' : lang;
};

export default {
    name: 'supportedLanguageDetector',

    lookup() {
        let languagesUsedByBrowser;

        if (navigator.userLanguage) {
            // noinspection JSDeprecatedSymbols - eslint complains about navigator.userLanguage, but this is the IE11-way of getting language
            languagesUsedByBrowser = [navigator.userLanguage];
        } else if (navigator.languages) {
            languagesUsedByBrowser = navigator.languages;
        } else if (navigator.language) {
            languagesUsedByBrowser = [navigator.language];
        }

        languagesUsedByBrowser = languagesUsedByBrowser.map(mergeNorwegianLanguages);

        const languagesSupportedByApplication = [];
        Constants.getSupportedLanguages().forEach(element => languagesSupportedByApplication.push(element.value));

        let language = defaultLanguage;
        for (let i = 0; i < languagesUsedByBrowser.length; i++) {
            if (languagesSupportedByApplication.includes(languagesUsedByBrowser[i])) {
                language = languagesUsedByBrowser[i];
                break;
            }
        }

        return language;
    }
};
