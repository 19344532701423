import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import Masonry from 'react-masonry-css';
import GroupCard from './GroupCard';
import { resourceServices } from '../../../../middlewares/resources';

/**
 * Main view for resource listings.
 */
const ResourcesTab = () => {
    const [resources, setResources] = useState([]);

    useEffect(() => {
        resourceServices.getAllResources()
            .then(items => {
                setResources(items);
            });
    }, []);

    const columnLayoutBreakpoints = {
        default: 2,
        700: 1
    };

    return (
        <Masonry
            breakpointCols={columnLayoutBreakpoints}
            className="resourceContainer"
        >
            {resources === null || resources === undefined || resources === []
                ? <h2>{i18next.t('NO_RESOURCES_AVAILABLE')}</h2>
                : resources.map((group, index) => (
                    <div
                        key={index}
                        className="resourceGroup">
                        <GroupCard
                            key={index}
                            group={group}
                            index={index}
                            isEditable={false}
                        />
                    </div>
                ))
            }
        </Masonry>
    );
};

export default withTranslation()(ResourcesTab);
