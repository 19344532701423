/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { SelectField, SelectionControlGroup, FontIcon } from 'react-md';
import _map from 'lodash/map';
import VpData from './VpData';
import { middlewares } from '../../../../../middlewares';

const VpContainer = props => {
    const { pConditions,
        vpPackageId,
        vpResult,
        studentResult,
        handleStudentSelectedValue,
        renderLeftBtn,
        renderRightBtn,
        isVisibleError,
        alertMsg,
        t } = props;
    const [age, setAge] = useState('');
    const [ageList, setAgeList] = useState([]);
    const [vpData, setVpData] = useState([]);

    useEffect(() => {
        const fetchVpTypes = async () => {
            const vpTypes = await middlewares.getVpTypes();
            setAgeList(_map(vpTypes, type => ({ label: type.type, value: type.id })));
        };

        fetchVpTypes();
    }, [age, vpPackageId]);

    const handleVpPackage = async val => {
        const vpDetails = await middlewares.getVpPackage(vpPackageId, val);
        setVpData(vpDetails.vpList);
        setAge(val);
    };

    const handleStudentSelectedVpPrioValue = val => {
        handleStudentSelectedValue({ selectedVpPrioValue: val });
    };

    return (
        <div>
            <div className="wrapper_container">
                <div className="wrapper">
                    <VpData vpData={vpData}/>
                    <label htmlFor="select-field-1">{t('AGE_GROUP')}</label>
                    <SelectField
                        id="select-field-1"
                        // label={t('AGE_GROUP')}
                        // placeholder="Placeholder"
                        className="md-cell"
                        value={age}
                        onChange={handleVpPackage}
                        menuItems={ageList}
        />
                </div>
                <div className="wrapper">
                    <div className="innerContainer">
                        <div className="questionCategoryHeading">{t('VITAL_SIGNS')}</div>
                        <div className="VpResults">
                            {_map(pConditions, (vp, i) => (
                                <div key={i}>
                                    {vp.key}
:
                                    {' '}
                                    {vp.value}
                                </div>
))}
                        </div>
                    </div>
                    <div className="stepsFooter">
                        {renderLeftBtn}
                        <div className="resultBoxContainer">
                            <div className="textAboveTickBox">
                                {t('CHOOSE_VS_PRIO')}
                            </div>
                            <div className="result_box">
                                <SelectionControlGroup
                                    id={vpResult.key}
                                    type="radio"
                                    value={studentResult.selectedVpPrioValue}
                                    onChange={handleStudentSelectedVpPrioValue}
                                    checkedRadioIcon={<FontIcon>check_box</FontIcon>}
                                    uncheckedRadioIcon={<FontIcon>check_box_outline_blank</FontIcon>}
                                    controls={vpResult.controls} />
                            </div>
                        </div>
                        {renderRightBtn}
                    </div>

                    {isVisibleError
                    && (
                    <div className="errorBox">
                        <FontIcon>error</FontIcon>
                        <span>{alertMsg}</span>
                        <span onClick={() => props.closeErrorMsg()}>X</span>
                    </div>
)}
                </div>
            </div>

        </div>
    );
};

export default withTranslation()(VpContainer);
