import axios from 'axios';

const uploadNewCourse = formData => {
    return axios.post(`${process.env.REACT_APP_API_BASEURL}/course`,
    formData,
    { headers: { accessToken: localStorage.getItem('accessToken'), 'content-type': 'multipart/form-data' } })
    .then(response => response);
};

const getAllActiveCourses = () => {
    return axios.get(`${process.env.REACT_APP_API_BASEURL}/course`,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => response.data);
};

const getSelectedCourse = id => {
    return axios.get(`${process.env.REACT_APP_API_BASEURL}/course/${id}`,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const deleteCourse = id => {
    return axios.delete(`${process.env.REACT_APP_API_BASEURL}/course/${id}`,
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const editCourse = formData => {
    return axios.post(`${process.env.REACT_APP_API_BASEURL}/course/editCourseById`,
    formData,
    { headers: { accessToken: localStorage.getItem('accessToken'), 'content-type': 'multipart/form-data' } })
    .then(response => {
        return response.data;
    });
};

const setCoursePosition = (from, to) => {
    return axios.post(`${process.env.REACT_APP_API_BASEURL}/course/setCoursePosition`,
    { data: { from, to } },
    { headers: { accessToken: localStorage.getItem('accessToken') } })
    .then(response => {
        return response.data;
    });
};

const courseServices = {
    uploadNewCourse,
    getAllActiveCourses,
    getSelectedCourse,
    deleteCourse,
    editCourse,
    setCoursePosition
};

export { courseServices };
