import { FontIcon, ListItem, MenuButton } from 'react-md';
import React from 'react';
import { withTranslation } from 'react-i18next';
import FileIconAvatar from './FileIconAvatar';
import { getLanguageCode, getLanguageName, getTitleOrTranslatedTitle } from './utils';

const FileRow = props => {
    const {
        file,
        key,
        children = []
    } = props;

    const lang = getLanguageCode();

    const getDescription = () => {
        const languages = file.languages ? file.languages.map(langCode => getLanguageName(langCode)).join(', ') : '';
        const years = file.years ? file.years.join(', ') : '';

        return `${languages}\n${years}`;
    };

    const navigateToItem = () => {
        if (file.uri !== null) {
            const win = window.open(file.uri, '_blank');
            win.focus();
        }
    };

    return (
        file
        ? (
            <ListItem
                key={key}
                leftAvatar={<FileIconAvatar extension={file.extension}/>}
                primaryText={getTitleOrTranslatedTitle(file.title, lang)}
                secondaryText={getDescription()}
                onClick={navigateToItem}
                renderChildrenOutside
                threeLines>
                {((children !== undefined) && children.length > 0) ? (
                    <MenuButton
                        // FIXME: Not sure what kind of id `id` should contain.
                        id={'Alternate versions menu'}
                        icon
                        tooltipLabel="Other versions"
                        menuItems={
                        children.map(child => (
                            child
                        ))
                    }>
                        <FontIcon>more_horiz</FontIcon>
                    </MenuButton>
            ) : null}
            </ListItem>
        ) : <p>No file</p>
    );
};

export default withTranslation()(FileRow);
