
export const alertInitialState = {
    alert: {
      displayAlert: false,
      alertActions: {},
    }
  };

export const alertActions = {
    alertPopup: (state, payload) => {
        const alertDetails = { displayAlert: payload.displayAlert, alertActions: payload.alertActions };

        return { alert: alertDetails };
      },
};
