import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import App from './App';
import Loader from './components/common/Loader.jsx';
import * as serviceWorker from './serviceWorker';


window.apiBaseUrl = process.env.REACT_APP_RETTSLIB_BASEURL;
console.log();
ReactDOM.render(
    <Suspense fallback={<Loader/>}>
        <I18nextProvider i18n={i18n}>
            <App />
        </I18nextProvider>
    </Suspense>, document.getElementById('root'));

serviceWorker.unregister();
