export const userInitialState = {
    user: {
      loggedIn: false,
      loginMsg: '',
      userRole: 'student',
      userDetails: {},
      token: '',
      view: 'student',
      logo: 'RETTSAcademy',
      queLang: '',
    }
};

export const userActions = {
    logout: () => {
      const userData = { loggedIn: false, path: '/', loginMsg: '', userRole: '' };
      localStorage.removeItem('accessToken');
      localStorage.removeItem('token');
      localStorage.removeItem('isRefreshed');
      localStorage.removeItem('view');
      localStorage.removeItem('roleId');

      return { user: userData };
    },

    login: (state, payload) => {
      return { user: {
        loggedIn: payload.isUserLoggedIn,
        loginMsg: payload.msg || '',
        view: localStorage.getItem('view') ? localStorage.getItem('view') : 'student',
        path: payload.path,
        userDetails: (payload.isUserLoggedIn && { username: payload.userDetails.username }) || {},
        token: payload.isUserLoggedIn && payload.userDetails.retts_jwt,
        logo: payload.logo || 'RETTSAcademy',
        roleId: payload.isUserLoggedIn && payload.roleId,
        userRole: (payload.isUserLoggedIn && payload.userRole) || 'student' } };
    },

    setView: (state, payload) => {
      localStorage.setItem('view', payload);

      return {
        user: {
          ...state.user,
          view: payload
        }
      };
    },

    setPath: (state, payload) => {
      return {
        user: {
          ...state.user,
          path: payload
        }
      };
    },

    setLogo: (state, payload) => {
      return {
        user: {
          ...state.user,
          logo: payload
        }
      };
    },
};
