import React, { useState } from 'react';
import Category from './Category';
import AddQuiz from './AddQuiz';
import ManageQuiz from './ManageQuiz';

export const Quiz = () => {
    const [selectedSection, setSelectedSection] = useState(1);
    const renderSelectedSection = () => {
        let section;
        switch (selectedSection) {
            case 1:
                section = (<AddQuiz/>);
                break;
            case 2:
                section = (<ManageQuiz/>);
                break;
            default:
                section = null;
                break;
        }

        return section;
    };

    const handleSection = sectionId => {
        setSelectedSection(sectionId);
    };

    return (
        <div className="ContentContainer">
            <Category handleSection={handleSection}/>
            {renderSelectedSection()}
        </div>
    );
};


export default Quiz;
