import React, { useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Autocomplete,
    Button,
    FontIcon,
    Grid,
    Cell,
    SelectField } from 'react-md';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _uniq from 'lodash/uniq';
import { useStore } from '../../../../../../store.js';
import { middlewares } from '../../../../../../middlewares';
import './AddQuiz.scss';

const FilterSection = props => {
    const {
        state: {
          quiz: { newQuiz },
          tags: { list },
          questions: { quesList, productList, packagesList }
        },
        dispatch
    } = useStore();

    const { t } = props;
    const [visibleNewRow, setVisibleNewRow] = useState(true);
    const [newKey, setNewKey] = useState('');
    const [newValue, setNewValue] = useState('');
    const [data, setData] = useState([]);

    const alertActions = useCallback(data => dispatch({ type: 'alertPopup', payload: data }), [dispatch]);
    const setNewFilter = useCallback(data => dispatch({ type: 'addNewFilter', payload: { data } }), [dispatch]);
    const removeFilter = useCallback(data => dispatch({ type: 'removeFilter', payload: data }), [dispatch]);
    const setFilteredQuestion = useCallback(data => dispatch({ type: 'setFilteredQuestion', payload: data }), [dispatch]);
    const setBulkSelectedQuesion = useCallback(data => dispatch({ type: 'setBulkSelectedQuesion', payload: data }), [dispatch]);
    const setNewQuizValues = useCallback((data, key) => dispatch({ type: 'setNewQuizValues', payload: { data, key } }), [dispatch]);

    const tagList = _map(list, item => item.tagname);
    const lastUpdatedByList = _uniq(_map(quesList, item => item.lastUpdatedBy));
    const createdByList = _uniq(_map(quesList, item => item.createdBy));
    const titleList = _uniq(_map(quesList, item => item.title));

    const stucturedProductList = _map(productList, list => ({ value: list.id, label: list.type }));
    const stucturedVeriosnList = _map(newQuiz.productVersionList, (val, i) => ({ key: i, value: val, label: val }));
    const stucturedProductLangList = _map(newQuiz.productLangList, val => ({ key: val.id, value: val.languageName, label: val.languageName }));

    const handleBackBtn = page => {
        props.handleSection(page);
    };

    const hideAlert = () => {
        alertActions({ displayAlert: false, alertActions: {} });
    };

    const setVisibleFilter = () => {
        setVisibleNewRow(true);
    };

    const setSelectedQuestions = data => {
        const filteredQuestion = _map(data, item => item._id);
        setBulkSelectedQuesion(filteredQuestion);
        props.handleSection(3);
        hideAlert();
    };

    const handleNextBtn = page => {
        middlewares.getQuestionsByFilter({ filters: newQuiz.filters, product: newQuiz.selectedProduct, version: newQuiz.selectedVersion, lang: newQuiz.selectedLang, type: newQuiz.type, count: newQuiz.questionCount })
        .then(data => {
            setFilteredQuestion(data);
            if (newQuiz.type === 'manual' || (newQuiz.type === 'random' && data.length >= newQuiz.questionCount)) {
                props.handleSection(page);
                newQuiz.type === 'random' && setSelectedQuestions(data);
            } else {
                const actions = {
                    showConfirmButton: true,
                    msg: 'The questions available for this filter is less than the given number of questions. Are you sure to proceed with less qusetions?',
                    okBtn: setSelectedQuestions,
                    cancelBtn: hideAlert,
                    callBackProps: data,
                };
                alertActions({ displayAlert: true, alertActions: actions });
            }
        });
    };

    const handleNewKey = e => {
        switch (e) {
            case 'title':
                setData(titleList);
                break;
            case 'tags':
                setData(tagList);
                break;
            case 'lastUpdatedBy':
                setData(lastUpdatedByList);
                break;
            case 'createdBy':
                setData(createdByList);
                break;
            default:
                setData({});
                break;
        }
        setNewKey(e);
        setNewValue('');
    };

    const handleNewValue = e => setNewValue(e);

    const SEARCH_BY = [{
            label: 'Title',
            value: 'title',
        }, {
            label: 'Tags',
            value: 'tags',
        }, {
            label: 'Last Updated By',
            value: 'lastUpdatedBy',
        }, {
            label: 'Created By',
            value: 'createdBy',
        }];

    const hideRow = () => {
        setVisibleNewRow(false);
        setNewValue('');
        setNewKey('');
    };

    const addNewFilter = () => {
        if (newKey && newValue) {
            setNewFilter({ key: newKey, value: newValue });
            setVisibleNewRow(false);
            setNewValue('');
            setNewKey('');
        }
    };

    const handleProductName = val => {
        setNewQuizValues(val, 'selectedProduct');
        setNewQuizValues('', 'selectedVersion');
        setNewQuizValues('', 'selectedLang');
        const fetchVersions = async () => {
            const versions = await middlewares.getAllVersionsByProduct(val)
            .then(list => list);
            setNewQuizValues(versions, 'productVersionList');
        };

        fetchVersions();
      };

      const handleVersion = val => {
        setNewQuizValues(val, 'selectedVersion');
        setNewQuizValues('', 'selectedLang');
        const fetchLang = async () => {
            const lang = await middlewares.getAllLangByVersion(val, newQuiz.selectedProduct)
            .then(list => list);
            setNewQuizValues(lang, 'productLangList');
        };

        fetchLang();
      };

      const handleLanguage = val => {
        setNewQuizValues(val, 'selectedLang');

        const productName = _filter(stucturedProductList, { value: newQuiz.selectedProduct })[0].label;

        if (packagesList) {
            const notReadypackageId = _filter(packagesList.notReady, { version: newQuiz.selectedVersion, type: productName, languageName: val });
            if (!notReadypackageId.length) {
                return true;
            }
            // eslint-disable-next-line no-alert
            alert(t('UNPUBLISHED_PACKAGE_MSG'));

            return false;
        }

        return false;
      };

    return (
        <div className="container">
            <div className="filerContainer">
                <div className="filterTitle">{'Filter questions:'}</div>
                <div className="addFilterBtn">
                    <Button flat primary iconChildren="add" onClick={setVisibleFilter}>Add Filters</Button>
                </div>

                {
                    _map(newQuiz.filters, (items, key) => {
                        if (items.length) {
                            return (
                                <div className="filterRow" key={key}>{`Search by ${key} :`}<div>
                                    {_map(items, (item, index) => (
                                        <div className="tags" key={index}>
                                            {item}
                                            <FontIcon
                                                onClick={() => removeFilter({ key, value: item })}>clear
                                            </FontIcon>
                                        </div>
                                          )
                                        )}
                                </div>
                                </div>
                            );
                        }

                        return <div />;
                    })
                }

                {visibleNewRow
                    && (
                        <Grid className="qRows" style={{ alignItems: 'flex-end' }}>
                            <Cell size={3}>
                                <span>
                                    <label htmlFor="select-field-1">{'Search By'}</label>
                                    <SelectField
                                        id="select-field-1"
                                        placeholder="Select"
                                        className="md-cell"
                                        menuItems={SEARCH_BY}
                                        value={newKey}
                                        onChange={handleNewKey}
                                    />
                                </span>
                            </Cell>
                            <Cell size={7}>
                                <Autocomplete
                                    id="newQValue"
                                    disabled={!newKey}
                                    data={data}
                                    onAutocomplete={handleNewValue}
                            />
                            </Cell>
                            <Cell className="qBtns" size={2}>
                                <Button primary icon iconChildren="clear" onClick={hideRow} />
                                <Button primary icon iconChildren="done" onClick={addNewFilter} />
                            </Cell>
                        </Grid>
                    )}

                <h2 className="categoryHeading">{t('CHOOSE_RETTS_VERSION')}</h2>
                <div className="veriosnDialogBox qCategory">
                    <label htmlFor="product-name">{t('PRODUCTS')}</label>
                    <SelectField
                        id="product-name"
                        className="md-cell"
                        onChange={handleProductName}
                        menuItems={stucturedProductList}
                        value={newQuiz.selectedProduct}
                        />
                    <label htmlFor="Version">{t('VERSION')}</label>
                    <SelectField
                        id="Version"
                        className="md-cell"
                        disabled={newQuiz.productVersionList ? !newQuiz.productVersionList.length : true}
                        onChange={handleVersion}
                        menuItems={stucturedVeriosnList}
                        value={newQuiz.selectedVersion}
                        />
                    <label htmlFor="Language">{t('LANGUAGE')}</label>
                    <SelectField
                        id="Language"
                        className="md-cell"
                        disabled={newQuiz.productLangList ? !newQuiz.productLangList.length : true}
                        onChange={handleLanguage}
                        menuItems={stucturedProductLangList}
                        value={newQuiz.selectedLang}
                        />
                </div>
            </div>

            <div className="footer">
                <Button flat secondary onClick={() => handleBackBtn(1)} iconChildren="navigate_before">Back</Button>
                <Button flat primary disabled={!newQuiz.selectedVersion || !newQuiz.selectedLang} onClick={() => handleNextBtn(3)} iconChildren="navigate_next" iconBefore={false}>
                    Next
                </Button>
            </div>
        </div>
    );
};

export default withTranslation()(FilterSection);
