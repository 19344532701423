import React from 'react';
import dateformat from 'dateformat';
import bg from '@internal/assets/diplomaBg.png';
import rettsLogo from '../../../Assets/retts_logo_small.png';

import './diploma.scss';
import './print.scss';
import './firefox.scss';
import './ie.scss';
import './chrome.scss';


const getDateNow = () => {
    const now = new Date();

    return dateformat(now, 'isoDate');
};

class DiplomaContent extends React.PureComponent {
    render() {
        const { name, courseName } = this.props;

        return (
            <div className="diplomaContent" >
                <img className="bgImage" src={bg} alt="background"/>
                <img className="rettsLogo" src={rettsLogo} alt="Retts Logo"/>
                <h2 className="studentName">{name}</h2>
                <h2 className="courseName">{courseName}</h2>
                <h2 className="completeDate">Date: {getDateNow()}</h2>
            </div>
        );
    }
}

export default DiplomaContent;
