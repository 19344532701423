/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Cell } from 'react-md';
import { withTranslation } from 'react-i18next';

const Facts = props => {
    const { renderLeftBtn, renderRightBtn, process, diagnosis, facts, diffDiagnosis } = props;

    return (
        <div>
            <div className="fact-wrapper">
                <div className="fact-container">
                    <Grid>
                        <Cell size={6} className="facts">
                            <div className="essHeading">{process.key}</div>
                            <div className="essContent">{process.value}</div>
                        </Cell>
                        <Cell size={6} className="facts">
                            <div className="essHeading">{diagnosis.key}</div>
                            <div className="essContent">{diagnosis.value}</div>
                        </Cell>
                    </Grid>
                    <Grid>
                        <Cell size={6} className="facts">
                            <div className="essHeading">{facts.key}</div>
                            <div className="essContent">{facts.value}</div>
                        </Cell>
                        <Cell size={6} className="facts">
                            <div className="essHeading">{diffDiagnosis.key}</div>
                            <div className="essContent">{diffDiagnosis.value}</div>
                        </Cell>
                    </Grid>
                </div>
            </div>
            <div className="stepsFooter finalFooterStep">
                {renderLeftBtn}
                {renderRightBtn}
            </div>
        </div>
    );
};

export default withTranslation()(Facts);
