/* eslint-disable react/prop-types */
import React, { useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import {
    DataTable,
    TableHeader,
    TableBody,
    TableRow,
    TableColumn,
    TablePagination,
    Button
  } from 'react-md';
  import _map from 'lodash/map';
  import { useStore } from '../../../../../../store.js';
  import { middlewares } from '../../../../../../middlewares';

const ManageQuiz = props => {
    const { t } = props;
    const defaultRowsPerPage = 25;

    const {
        state: {
            quiz: { quizList },
            user: { userDetails }
        },
        dispatch
      } = useStore();

    const [rows, setRows] = useState(quizList.length);
    const [sno, setSno] = useState(0);
    const [editId, setEditId] = useState(0);
    const [editValue, setEditValue] = useState('');
    const [slicedData, setSlicedData] = useState(quizList.slice(0, defaultRowsPerPage));
    const alertActions = useCallback(data => dispatch({ type: 'alertPopup', payload: data }), [dispatch]);
    const setQuizList = useCallback(data => dispatch({ type: 'setQuizList', payload: data }), [dispatch]);

    const handlePagination = (start, rowsPerPage) => {
        setSno(start / rowsPerPage);
        setSlicedData(quizList.slice(start, start + rowsPerPage));
    };

    const hideAlert = () => {
        alertActions({ displayAlert: false, alertActions: {} });
    };

    const deleteQuiz = id => {
        const deleteQuizAttempt = middlewares.deleteQuiz({ id, name: userDetails.username });
        deleteQuizAttempt.then(res => {
            setRows(res.data.length);
            setQuizList(res.data);
            setSlicedData(res.data.slice(sno * defaultRowsPerPage, (sno * defaultRowsPerPage) + defaultRowsPerPage));
            hideAlert();
        });
    };

    const showDeleteAlert = id => {
        const actions = {
            showConfirmButton: true,
            msg: t('DELETE_CONFIRM_MSG'),
            okBtn: deleteQuiz,
            cancelBtn: hideAlert,
            callBackProps: id,
        };
        alertActions({ displayAlert: true, alertActions: actions });
    };

    const handleEdit = (id, val) => {
        setEditId(id);
        setEditValue(val);
    };

    const updateQuizTitle = () => {
        console.log('==hit');

        const editQuizAttempt = middlewares.editQuizTitle({ id: editId, title: editValue });
        editQuizAttempt.then(res => {
            setRows(res.data.length);
            setQuizList(res.data);
            setSlicedData(res.data.slice(sno * defaultRowsPerPage, (sno * defaultRowsPerPage) + defaultRowsPerPage));
            hideAlert();
            handleEdit('', '');
        });
    };

    const tableHeader = () => {
        return (
            <TableRow selectable={false}>
                <TableColumn>S.No</TableColumn>
                <TableColumn>{t('TITLE')}</TableColumn>
                <TableColumn>{t('NUMBER_OF_QUESTIONS')}</TableColumn>
                <TableColumn>{t('LANGUAGE')}</TableColumn>
                <TableColumn>{t('CREATED_BY')}</TableColumn>
                <TableColumn>{t('LAST_UPDATED_BY')}</TableColumn>
                <TableColumn>{t('EDIT')}</TableColumn>
                <TableColumn>{t('DELETE')}</TableColumn>
            </TableRow>
        );
    };

    return (
        <div className="ManageQuestionsContainer">
            <div className="innerContainer">
                <DataTable plain>
                    <TableHeader>
                        {tableHeader()}
                    </TableHeader>
                    <TableBody>
                        {_map(slicedData, (item, i) => (
                            <TableRow key={item._id}>
                                <TableColumn>{i + (sno * defaultRowsPerPage) + 1}</TableColumn>
                                <TableColumn >
                                    {editId === item._id
                                        ? (
                                            <div className="editQuizTitle">
                                                <input
                                                    value={editValue}
                                                    onChange={e => setEditValue(e.target.value)}/>
                                                <Button icon primary onClick={updateQuizTitle}>done</Button>
                                                <Button icon primary onClick={() => { handleEdit('', ''); }}>close</Button>
                                            </div>
                                        ) : item.title}
                                </TableColumn>
                                <TableColumn>
                                    {item.selectedQuestions.length}
                                </TableColumn>
                                <TableColumn>{item.selectedLang}</TableColumn>
                                <TableColumn>{item.createdBy}</TableColumn>
                                <TableColumn>{item.lastUpdatedBy}</TableColumn>
                                <TableColumn>
                                    <Button icon disabled={editId === item._id} primary onClick={() => handleEdit(item._id, item.title)} iconChildren="edit" />
                                </TableColumn>
                                <TableColumn>
                                    <Button icon secondary onClick={() => showDeleteAlert(item._id)} iconChildren="delete_sweep" />
                                </TableColumn>
                            </TableRow>
                            ))}
                    </TableBody>
                </DataTable>

                <div className="footer">
                    <DataTable baseId="simple-pagination">
                        <TablePagination
                            rows={rows}
                            defaultPage={sno + 1}
                            defaultRowsPerPage={defaultRowsPerPage}
                            onPagination={handlePagination} />
                    </DataTable>
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(ManageQuiz);
