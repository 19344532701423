import React, { useState } from 'react';
import { Button, TextField } from 'react-md';
import { withTranslation } from 'react-i18next';

const GroupEditControls = props => {
    const { group, onSave, onCancel, lang } = props;

    const [newGroupValues, setGroupValues] = useState(group);

    const onTitleChange = newTitle => {
        newGroupValues.title[lang] = newTitle;
        setGroupValues(newGroupValues);
    };

    const onDescriptionChange = newDescription => {
        newGroupValues.description[lang] = newDescription;
        setGroupValues(newGroupValues);
    };

    const onClickSave = () => {
        console.log(`New values: ${newGroupValues}`);
        onSave(newGroupValues);
    };

    const onClickCancel = () => {
        setGroupValues(group);
        onCancel();
    };

    return (
        <div>
            <h3>{`Group #${group.index}: "${group.name}"`}</h3>
            <TextField
                id="title-input-field"
                label="Title"
                defaultValue={group.title[lang]}
                onChange={onTitleChange}
                            />
            <TextField
                id="description-input-field"
                label="Description"
                defaultValue={group.description[lang]}
                onChange={onDescriptionChange}
                            />
            <Button raised primary iconClassName="fa fa-save" onClick={onClickSave}>Save</Button>
            <Button raised iconClassName="" onClick={onClickCancel} >Cancel</Button>
        </div>
    );
};

export default withTranslation()(GroupEditControls);
