/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { FontIcon } from 'react-md';

const Category = props => {
    const { t } = props;
    const [tagClass, setTagClass] = useState('category');
    const [addQuestionClass, setAddQuestionClass] = useState('category active');
    const [ManageQuestionClass, setManageQuestionClass] = useState('category');

    const handleManageQuestions = () => {
        setTagClass('category');
        setAddQuestionClass('category');
        setManageQuestionClass('category active');
        props.handleSection(2);
    };

    const handleTags = () => {
        setTagClass('category active');
        setAddQuestionClass('category');
        setManageQuestionClass('category');
        props.handleSection(3);
    };

    const handleAddQuestions = () => {
        setTagClass('category');
        setAddQuestionClass('category active');
        setManageQuestionClass('category');
        props.handleSection(1);
    };

    return (
        <div className="categoryList">
            <div className={addQuestionClass} onClick={handleAddQuestions}>
                <FontIcon>playlist_add</FontIcon>
                {t('ADD_QUESTIONS')}
            </div>
            <div className={ManageQuestionClass} onClick={handleManageQuestions}>
                <FontIcon>playlist_add_check</FontIcon>
                {t('MANAGE_QUESTIONS')}
            </div>
            <div className={tagClass} onClick={handleTags}>
                <FontIcon>label</FontIcon>
                {t('TAGS')}
            </div>
        </div>
    );
};

export default withTranslation()(Category);
