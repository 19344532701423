/* eslint-disable react/prop-types */
// import 'canvas-5-polyfill';
import React, { useState, useEffect, useCallback } from 'react';
// import MagicBoard from './MagicBoard';
import { withTranslation } from 'react-i18next';
import {
    DataTable,
    // TableHeader,
    TableBody,
    SelectField,
    TableRow,
    TableColumn,
    TablePagination,
  } from 'react-md';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import _filter from 'lodash/filter';
import { middlewares } from '../../../../../middlewares';
import ViewQuiz from '../../Common/ViewQuiz';
import { useStore } from '../../../../../store.js';
import './Quiz.scss';
import ProgressBar from '../../Common/ProgressBar/ProgressBar';

const Quiz = props => {
     const { t } = props;
    const defaultRowsPerPage = 25;

    const {
        state: {
            quiz: { quizList },
            user: { queLang },
        }, dispatch
    } = useStore();

    const list = _filter(quizList, data => data.selectedLang === queLang);
    const [quizListByLang, setQuizListByLang] = useState(list);
    const [rows, setRows] = useState(quizListByLang.length);
    const [selectedLang, setSelectedLang] = useState(queLang);
    const [sno, setSno] = useState(0);
    const [slicedData, setSlicedData] = useState(quizListByLang.slice(0, defaultRowsPerPage));
    const [openQuiz, setOpenQuiz] = useState(false);
    const [selectedQuizData, setSelectedQuizData] = useState({});

    const setQuizList = useCallback(data => dispatch({ type: 'setQuizList', payload: data }), [dispatch]);


    useEffect(() => {
        const fetchData = async() => {
            await middlewares.getAllQuiz()
            .then(list => {
                setQuizList(list);
                const data = _filter(list, data => data.selectedLang === selectedLang);
                setSlicedData(data.slice(0, defaultRowsPerPage));
            });
        };
        fetchData();
    }, [openQuiz]);

    const handlePagination = (start, rowsPerPage) => {
        setSno(start / rowsPerPage);
        setSlicedData(quizListByLang.slice(start, start + rowsPerPage));
    };

    const handleTableRowClick = item => {
        setSelectedQuizData(item);
        setOpenQuiz(true);
    };

    // const setQuizList = useCallback(data => dispatch({ type: 'setQuizList', payload: data }), [dispatch]);

    const closeQuiz = async quiz => {
        setSelectedQuizData();
        setOpenQuiz(false);
        if (quiz._id) {
            const newQuizList = await middlewares.setQuizStatus({ status: 'PASSED', id: quiz._id })
            .then(list => list);
            setQuizList(newQuizList);
        }
    };

    const handleLanguageOnChange = e => {
        setQuizListByLang([]);
        const filteredList = _filter(quizList, data => data.selectedLang === e);
        setSelectedLang(e);
        setQuizListByLang(filteredList);
        setRows(filteredList.length);
        if (filteredList.length) {
            setSlicedData(filteredList.slice(0, defaultRowsPerPage));
        } else {
            setSlicedData([]);
        }
    };

    const languageList = _map(_uniqBy(quizList, 'selectedLang'), list => {
        return ({
            id: list.selectedLang,
            value: list.selectedLang
        });
    });

    return (
        <div className="OuterContainer">
            {!openQuiz && (
                <div className="selectBox">
                    <label htmlFor="languageList">{t('SELECT_QUIZ_BY_LANGUAGE')}</label>
                    <SelectField
                        id="languageList"
                        className="quizlangSelectBox md-cell"
                        onChange={handleLanguageOnChange}
                        value={selectedLang}
                        menuItems={languageList}
                        position={SelectField.Positions.BELOW}
                        />
                </div>
            )}
            {!openQuiz ? (
                <div className="ManageQuestionsContainer studentContainer">
                    {
                        quizListByLang.length
                            ? (
                                <DataTable plain>
                                    <TableBody>
                                        {_map(slicedData, (item, i) => (
                                            <TableRow onClick={() => handleTableRowClick(item)} key={i}>
                                                <TableColumn>
                                                    <h3>{item.title}</h3>
                                                    <div>{`${t('NUMBER_OF_QUESTIONS')} : ${item.selectedQuestions.length}`}</div>
                                                    <div className="pSituationText">{item.description}</div>
                                                    <div className="pSituationText"> {`${t('LANGUAGE')} : ${item.selectedLang}`}</div>
                                                </TableColumn>
                                                <TableColumn><ProgressBar status={item.status}/></TableColumn>
                                            </TableRow>
                                    ))}
                                    </TableBody>
                                </DataTable>
                            ) : (
                                <div className="noResult">
                                    <h1>No Quiz available</h1>
                                </div>
                            )
                    }
                    {(slicedData.length > defaultRowsPerPage) && (
                        <div className="footer">
                            <DataTable baseId="simple-pagination">
                                <TablePagination
                                    rows={rows}
                                    defaultPage={sno + 1}
                                    defaultRowsPerPage={defaultRowsPerPage}
                                    onPagination={handlePagination} />
                            </DataTable>
                        </div>
                        )
                    }
                </div>
        ) : <ViewQuiz onBack={closeQuiz} quizData={selectedQuizData}/>}
        </div>
    );
};

export default withTranslation()(Quiz);
